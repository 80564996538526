import React from "react";
import {useFormikContext} from "formik";
import LineComponent from "../../../../../common/ui/Line";
import InputComponent from "../../../../../common/Input";

export default function BusinessLegalProtection4({translation, printRef}) {
    const formikProps = useFormikContext();

    return (
        <div ref={printRef}>
            <div className={'item'}>
                <h5>{translation("ASSISTA_Generic_Form_Label_Step4")}</h5>
            </div>

            <div className={'item'}>
                <h6>{translation('stepper4Label')}</h6>
                {formikProps.values['assistaDocument'] !== '' &&
                    <>
                        {formikProps.values['assistaDocument'].map((file, index) => (
                            <p key={index}>{file.name}</p>
                        ))}
                    </>
                }
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Step4_Field_Yours_Insurances")}</h6>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Step4_Field_Other_Protection")}</h6>
                <p>{formikProps.values['assistaOtherProtection']}</p>
            </div>

            {formikProps.values['assistaOtherProtection'] === translation('yesLabel') &&
                <>
                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Step4_Field_If_Yes")}</h6>
                        <p>{formikProps.values['assistaProtectionName']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Step4_Field_Case_Reported")}</h6>
                        <p>{formikProps.values['assistaCaseReported']}</p>
                    </div>
                </>
            }

            <div className={'item'}>
                <h6>{translation('ASSISTA_Generic_Form_Field_Refused_By_Other_Company')}</h6>
                <p>{formikProps.values['assistaCompanyRefused']}</p>
            </div>

            {formikProps.values['assistaCompanyRefused'] === translation('yesLabel') &&
                <>
                    <div className={'item'}>
                        <h6>{translation('ASSISTA_Generic_Form_Field_Company_Name')}</h6>
                        <p>{formikProps.values['assistaCompanyRefusedName']}</p>
                    </div>
                </>
            }

        </div>
    )
}