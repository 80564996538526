import InputComponent from "./Input";
import React from "react";

export default function CounterPartyItem({translation, prefix, isRequired = true, translationLabel = "ASSISTA_Generic_Form_Step3_H3_Counter_Party", titleLabel = true}) {
    const getLabel = (label) => {
        return isRequired ? label + "*" : label + "";
    }

    return(
        <>
            {titleLabel &&
                <div className={'item'}>
                    <h5>
                        {translation(translationLabel)}
                    </h5>
                </div>
            }

            <div className={'item-names'}>
                <InputComponent
                    data={{
                        name: prefix + 'Name',
                        label: getLabel(translation('firstnameLabel')),
                        inputMode: "text"
                    }}
                />
                <InputComponent
                    data={{
                        name: prefix + 'Firstname',
                        label: getLabel(translation('surnameLabel')),
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item-names'}>
                <InputComponent
                    data={{
                        name: prefix + 'Company',
                        label: getLabel(translation('holderLabel')),
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item-street'}>
                <InputComponent
                    data={{
                        name: prefix + 'Address',
                        label: getLabel(translation('addressLabel')),
                        inputMode: "text",
                        component: "textarea",
                        rows: "3"
                    }}
                />
            </div>

            <div className={'item-street'}>
                <InputComponent
                    data={{
                        name: prefix + 'AddressNPA',
                        label: getLabel(translation('npaLabel')),
                        inputMode: "numeric"
                    }}
                />
                <InputComponent
                    data={{
                        name: prefix + 'AddressCity',
                        label: getLabel(translation('cityLabel')),
                        className: "city",
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: prefix + 'AddressLand',
                        label: getLabel(translation('landLabel')),
                        inputMode: "text"
                    }}
                />
            </div>
        </>
    )
}