import React from "react";
import {useFormikContext} from "formik";
import LineComponent from "../../../../../common/ui/Line";

export default function BusinessLegalProtection3({translation, printRef}) {
    const formikProps = useFormikContext();

    const getLabelGender = (gender) => {
        switch (gender) {
            case 'male':
                return translation('genderMaleLabel');
            case 'female':
                return translation('genderFemaleLabel');
            case 'other':
            default:
                return translation('genderOtherLabel');
        }
    }

    return (
        <div ref={printRef}>
            <div className={'item'}>
                <h5>{translation("ASSISTA_Generic_Form_Label_Step3")}</h5>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Label_Type_Case")}</h6>
                <p>{formikProps.values['assistaCompanyLegalCase']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Label_Dispute_Type")}</h6>
                <p>{formikProps.values['assistaCompanyTypeDispute']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Date")}</h6>
                <p>{formikProps.values['assistaCompanyDateDamage']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation('exactPlaceLabel')}</h6>
                <p>{formikProps.values['assistaCompanyExactPlace']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation('landLabel')}</h6>
                <p>{formikProps.values['assistaCompanyLand']}</p>
            </div>

            <div className={'item'}>
                <h5>
                    {translation("ASSISTA_Generic_Form_H3_Damage_Cause")}
                </h5>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Description")}</h6>
                <p>{formikProps.values['assistaCompanyDescriptionCaseDamage']}</p>
            </div>

            <div className={'item'}>
                <h5>
                    {translation("ASSISTA_Generic_Form_H3_Damage_Amount")}
                </h5>
            </div>

            <div className={'item'}>
                <h6>{translation('chfAmountLabel')}</h6>
                <p>{formikProps.values['assistaCompanyCompensation']}</p>
            </div>

            {(formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal")
                || formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor")
                || formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner")) &&
                <>
                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_Step3_H3_Counter_Party")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable")}</h6>
                        <p>{formikProps.values['assistaCompanyCounterPartyHolderName']}</p>
                    </div>

                    <div className={'item'}>
                        <div>
                            <p>{getLabelGender(formikProps.values['assistaCompanyCounterPartyGender'])}</p>
                        </div>
                    </div>

                    <div className={'item-names'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                            <p>{formikProps.values['assistaCompanyCounterPartyFirstname']}</p>
                        </div>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                            <p>{formikProps.values['assistaCompanyCounterPartySurname']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                            <p>{formikProps.values['assistaCompanyCounterPartyAddress']}</p>
                        </div>
                    </div>

                    <div className={'item-street'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                            <p>{formikProps.values['assistaCompanyCounterPartyAddressNPA']}</p>
                        </div>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                            <p>{formikProps.values['assistaCompanyCounterPartyAddressCity']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                        <p>{formikProps.values['assistaCompanyCounterPartyAddressLand']}</p>
                    </div>


                </>
            }
            {formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                <>
                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Accident_Report")}</h6>
                        <p>{formikProps.values['assistaCompanyAccidentReportMade']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Acknowledgment_Debt")}</h6>
                        <p>{formikProps.values['assistaCompanySignedAcknowledgment']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Acknowledgment_Debt_Opposing_Party")}</h6>
                        <p>{formikProps.values['assistaCompanySignedAcknowledgmentOpposingParty']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Police_There")}</h6>
                        <p>{formikProps.values['assistaCompanyPoliceHere']}</p>
                    </div>

                    {formikProps.values['assistaCompanyPoliceHere'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Label_Police_Report")}</h6>
                                <p>{formikProps.values['assistaCompanyPoliceReportMade']}</p>
                            </div>

                            {formikProps.values['assistaCompanyPoliceReportMade'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Police_Station")}</h6>
                                        <p>{formikProps.values['assistaCompanyPoliceStation']}</p>
                                    </div>

                                    <div className={'item'}>
                                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Accused_Of")}</h6>
                                        <p>{formikProps.values['assistaCompanyAccusedOf']}</p>
                                    </div>
                                </>
                            }
                        </>
                    }

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_License_Withdrawn")}</h6>
                        <p>{formikProps.values['assistaCompanyLicenseTakenAway']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Blood_Test")}</h6>
                        <p>{formikProps.values['assistaCompanyBloodTest']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Criminal_Measure")}</h6>
                        <p>{formikProps.values['assistaCompanyCriminalMeasure']}</p>
                    </div>

                    {formikProps.values['assistaCompanyCriminalMeasure'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Field_Criminal_Measure_Date_Receipt")}</h6>
                                <p>{formikProps.values['assistaCompanyCriminalMeasureDate']}</p>
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_People_Injured")}</h6>
                        <p>{formikProps.values['assistaCompanyPeopleInjured']}</p>
                    </div>

                    {formikProps.values['assistaCompanyPeopleInjured'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Field_People_Injured_Information")}</h6>
                                <p>{formikProps.values['assistaCompanyPeopleInjuredInformations']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Label_Declaration_Made")}</h6>
                                <p>{formikProps.values['assistaCompanyDeclarationAccidentInsurance']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Label_Other_People_Injured")}</h6>
                                <p>{formikProps.values['assistaCompanyOtherPeopleInjured']}</p>
                            </div>

                            {formikProps.values['assistaCompanyOtherPeopleInjured'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <h6>{translation("ASSISTA_Company_Form_Step3_Field_People_Injured_Information")}</h6>
                                        <p>{formikProps.values['assistaCompanyOtherPeopleInjuredInformations']}</p>
                                    </div>

                                    <div className={'item'}>
                                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Declaration_Made")}</h6>
                                        <p>{formikProps.values['assistaCompanyOtherDeclarationAccidentInsurance']}</p>
                                    </div>
                                </>
                            }
                        </>
                    }
                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_Step3_H3_Vehicle_Informations")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Brand")}</h6>
                        <p>{formikProps.values['assistaCompanyMark']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Plate")}</h6>
                        <p>{formikProps.values['assistaCompanyModel']}</p>
                    </div>

                    <div className={'item-names'}>
                        <div>
                            <h6>{translation('firstDatePlacingMarketLabel')}</h6>
                            <p>{formikProps.values['assistaCompanyFirstDatePlacingMarket']}</p>
                        </div>
                        <div>
                            <h6>{translation('kilometerStatusLabel')}</h6>
                            <p>{formikProps.values['assistaCompanyKilometerStatus']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Items_Damaged")}</h6>
                        <p>{formikProps.values['assistaCompanyItemsDamaged']}</p>
                    </div>

                    {formikProps.values['assistaCompanyItemsDamaged'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Field_List_Items_Damaged")}</h6>
                                <p>{formikProps.values['assistaCompanyListItemsDamaged']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Field_Amount_Items_Damaged")}</h6>
                                <p>{formikProps.values['assistaCompanyOwnerItemsAmountDamagedCompany']}</p>
                            </div>

                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Owner_Items")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable")}</h6>
                                <p>{formikProps.values['assistaCompanyOwnerItemsDamagedCompany']}</p>
                            </div>

                            <div className={'item'}>
                                <div>
                                    <p>{getLabelGender(formikProps.values['assistaCompanyOwnerItemsDamagedGender'])}</p>
                                </div>
                            </div>

                            <div className={'item-names'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                                    <p>{formikProps.values['assistaCompanyOwnerItemsDamagedFirstname']}</p>
                                </div>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                                    <p>{formikProps.values['assistaCompanyOwnerItemsDamagedSurname']}</p>
                                </div>
                            </div>

                            <div className={'item'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                                    <p>{formikProps.values['assistaCompanyOwnerItemsDamagedAddress']}</p>
                                </div>
                            </div>

                            <div className={'item-street'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                                    <p>{formikProps.values['assistaCompanyOwnerItemsDamagedAddressNPA']}</p>
                                </div>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                                    <p>{formikProps.values['assistaCompanyOwnerItemsDamagedAddressCity']}</p>
                                </div>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                                <p>{formikProps.values['assistaCompanyOwnerItemsDamagedAddressLand']}</p>
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Expertise")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Assessed_Expert")}</h6>
                        <p>{formikProps.values['assistaCompanyVehicleAssessed']}</p>
                    </div>

                    {formikProps.values['assistaCompanyVehicleAssessed'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Field_Assessed_Expert_Name")}</h6>
                                <p>{formikProps.values['assistaCompanyVehicleExpertName']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Field_Assessed_Expert_Mandated_By")}</h6>
                                <p>{formikProps.values['assistaCompanyVehicleExpertMandated']}</p>
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Vehicle_Repared")}</h6>
                        <p>{formikProps.values['assistaCompanyVehicleRepaired']}</p>
                    </div>

                    {formikProps.values['assistaCompanyVehicleRepaired'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Label_Vehicle_Repared_By")}</h6>
                                <p>{formikProps.values['assistaCompanyVehicleRepairedBy']}</p>
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Company_Form_Step3_Label_Vehicle_Insurance")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Field_Vehicle_Insurance_Name")}</h6>
                        <p>{formikProps.values['assistaCompanyNameInsuranceVehicle']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Police_Number")}</h6>
                        <p>{formikProps.values['assistaCompanyPoliceNumberVehicle']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Damage_Claimed")}</h6>
                        <p>{formikProps.values['assistaCompanyVehicleDeclaredIncident']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Assurance_Casco")}</h6>
                        <p>{formikProps.values['assistaCompanyVehicleCasco']}</p>
                    </div>

                    {formikProps.values['assistaCompanyVehicleCasco'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Police_Number")}</h6>
                                <p>{formikProps.values['assistaCompanyCascoPoliceNumber']}</p>
                            </div>

                            <div className={'item'}>
                                <p>{formikProps.values['assistaCompanyCascoFullOrPartial']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Field_Deductible_Casco")}</h6>
                                <p>{formikProps.values['assistaCompanyCascoFranchise']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Label_Casco_Claimed")}</h6>
                                <p>{formikProps.values['assistaCompanyVehicleCascoDeclared']}</p>
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_Field_Vehicle_Owner")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Company_Own_Vehicle")}</h6>
                        <p>{formikProps.values['assistaCompanyVehicleCompanyOwner']}</p>
                    </div>

                    {formikProps.values['assistaCompanyVehicleCompanyOwner'] === translation('noLabel') &&
                        <>
                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable")}</h6>
                                <p>{formikProps.values['assistaCompanyVehicleOwnerCompany']}</p>
                            </div>

                            <div className={'item'}>
                                <div>
                                    <p>{getLabelGender(formikProps.values['assistaCompanyVehicleOwnerGender'])}</p>
                                </div>
                            </div>

                            <div className={'item-names'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                                    <p>{formikProps.values['assistaCompanyVehicleOwnerFirstname']}</p>
                                </div>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                                    <p>{formikProps.values['assistaCompanyVehicleOwnerSurname']}</p>
                                </div>
                            </div>

                            <div className={'item'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                                    <p>{formikProps.values['assistaCompanyVehicleOwnerAddress']}</p>
                                </div>
                            </div>

                            <div className={'item-street'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                                    <p>{formikProps.values['assistaCompanyVehicleOwnerAddressNPA']}</p>
                                </div>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                                    <p>{formikProps.values['assistaCompanyVehicleOwnerAddressCity']}</p>
                                </div>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                                <p>{formikProps.values['assistaCompanyVehicleOwnerAddressLand']}</p>
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Driver")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <div>
                            <p>{getLabelGender(formikProps.values['assistaCompanyVehicleDriverGender'])}</p>
                        </div>
                    </div>

                    <div className={'item-names'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                            <p>{formikProps.values['assistaCompanyVehicleDriverFirstname']}</p>
                        </div>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                            <p>{formikProps.values['assistaCompanyVehicleDriverSurname']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                            <p>{formikProps.values['assistaCompanyVehicleDriverAddress']}</p>
                        </div>
                    </div>

                    <div className={'item-street'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                            <p>{formikProps.values['assistaCompanyVehicleDriverAddressNPA']}</p>
                        </div>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                            <p>{formikProps.values['assistaCompanyVehicleDriverAddressCity']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                        <p>{formikProps.values['assistaCompanyVehicleDriverAddressLand']}</p>
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_Text_Driving_License")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Driving_License_Type")}</h6>
                        <p>{formikProps.values['assistaCompanyVehicleDriverLicenseCategory']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Driving_License_Date")}</h6>
                        <p>{formikProps.values['assistaCompanyVehicleDriverLicenseDate']}</p>
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_Step3_H3_Counter_Party")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable")}</h6>
                        <p>{formikProps.values['assistaCompanyCounterPartyHolderName']}</p>
                    </div>

                    <div className={'item'}>
                        <div>
                            <p>{getLabelGender(formikProps.values['assistaCompanyCounterPartyGender'])}</p>
                        </div>
                    </div>

                    <div className={'item-names'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                            <p>{formikProps.values['assistaCompanyVehicleDriverFirstname']}</p>
                        </div>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                            <p>{formikProps.values['assistaCompanyVehicleDriverSurname']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                            <p>{formikProps.values['assistaCompanyCounterPartyAddress']}</p>
                        </div>
                    </div>

                    <div className={'item-street'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                            <p>{formikProps.values['assistaCompanyCounterPartyAddressNPA']}</p>
                        </div>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                            <p>{formikProps.values['assistaCompanyCounterPartyAddressCity']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                        <p>{formikProps.values['assistaCompanyCounterPartyAddressLand']}</p>
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Data_Opposing_Party_If_Relevant")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Brand")}</h6>
                        <p>{formikProps.values['assistaCompanyCounterPartyMark']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Plate")}</h6>
                        <p>{formikProps.values['assistaCompanyCounterPartyModel']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Step3_Field_Vehicle_Insurance")}</h6>
                        <p>{formikProps.values['assistaCompanyCounterPartyNameInsuranceVehicle']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step3_Label_Opposing_Party_Owner")}</h6>
                        <p>{formikProps.values['assistaCompanyCounterPartyOwner']}</p>
                    </div>

                    {formikProps.values['assistaCompanyCounterPartyOwner'] === translation('noLabel') &&
                        <>
                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_Field_Vehicle_Owner")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable")}</h6>
                                <p>{formikProps.values['assistaCompanyCounterPartyOwnerCompanyName']}</p>
                            </div>

                            <div className={'item'}>
                                <div>
                                    <p>{getLabelGender(formikProps.values['assistaCompanyCounterPartyOwnerGender'])}</p>
                                </div>
                            </div>

                            <div className={'item-names'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                                    <p>{formikProps.values['assistaCompanyVehicleDriverFirstname']}</p>
                                </div>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                                    <p>{formikProps.values['assistaCompanyVehicleDriverSurname']}</p>
                                </div>
                            </div>

                            <div className={'item'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                                    <p>{formikProps.values['assistaCompanyCounterPartyAddress']}</p>
                                </div>
                            </div>

                            <div className={'item-street'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                                    <p>{formikProps.values['assistaCompanyCounterPartyOwnerAddressNPA']}</p>
                                </div>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                                    <p>{formikProps.values['assistaCompanyCounterPartyOwnerAddressCity']}</p>
                                </div>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                                <p>{formikProps.values['assistaCompanyCounterPartyOwnerAddressLand']}</p>
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Witnesses")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <div>
                            <h6>{translation("ASSISTA_Company_Form_Step3_Label_Witnesses")}</h6>
                            <p>{formikProps.values['assistaCompanyWitness']}</p>
                        </div>
                    </div>

                    {formikProps.values['assistaCompanyWitness'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Company_Form_Step3_H3_Witness1_Informations")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <div>
                                    <p>{getLabelGender(formikProps.values['assistaCompanyWitness1Gender'])}</p>
                                </div>
                            </div>

                            <div className={'item-names'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                                    <p>{formikProps.values['assistaCompanyWitness1Firstname']}</p>
                                </div>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                                    <p>{formikProps.values['assistaCompanyWitness1Surname']}</p>
                                </div>
                            </div>

                            <div className={'item'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                                    <p>{formikProps.values['assistaCompanyWitness1Address']}</p>
                                </div>
                            </div>

                            <div className={'item-street'}>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                                    <p>{formikProps.values['assistaCompanyWitness1AddressNPA']}</p>
                                </div>
                                <div>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                                    <p>{formikProps.values['assistaCompanyWitness1AddressCity']}</p>
                                </div>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                                <p>{formikProps.values['assistaCompanyWitness1AddressLand']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Phone")}</h6>
                                <p>{formikProps.values['assistaCompanyWitness1Phone']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Generic_Form_Field_Email")}</h6>
                                <p>{formikProps.values['assistaCompanyWitness1Email']}</p>
                            </div>

                            <div className={'item'}>
                                <h6>{translation("ASSISTA_Company_Form_Step3_Label_Others_Witnesses")}</h6>
                                <p>{formikProps.values['assistaCompanyOtherWitness']}</p>
                            </div>

                            {formikProps.values['assistaCompanyOtherWitness'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <h5>
                                            {translation("ASSISTA_Company_Form_Step3_H3_Witness2_Informations")}
                                        </h5>
                                    </div>

                                    <div className={'item'}>
                                        <div>
                                            <p>{getLabelGender(formikProps.values['assistaCompanyWitness2Gender'])}</p>
                                        </div>
                                    </div>

                                    <div className={'item-names'}>
                                        <div>
                                            <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                                            <p>{formikProps.values['assistaCompanyWitness2Firstname']}</p>
                                        </div>
                                        <div>
                                            <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                                            <p>{formikProps.values['assistaCompanyWitness2Surname']}</p>
                                        </div>
                                    </div>

                                    <div className={'item'}>
                                        <div>
                                            <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                                            <p>{formikProps.values['assistaCompanyWitness2Address']}</p>
                                        </div>
                                    </div>

                                    <div className={'item-street'}>
                                        <div>
                                            <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                                            <p>{formikProps.values['assistaCompanyWitness2AddressNPA']}</p>
                                        </div>
                                        <div>
                                            <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                                            <p>{formikProps.values['assistaCompanyWitness2AddressCity']}</p>
                                        </div>
                                    </div>

                                    <div className={'item'}>
                                        <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                                        <p>{formikProps.values['assistaCompanyWitness2AddressLand']}</p>
                                    </div>

                                    <div className={'item'}>
                                        <h6>{translation("ASSISTA_Generic_Form_Field_Phone")}</h6>
                                        <p>{formikProps.values['assistaCompanyWitness2Phone']}</p>
                                    </div>

                                    <div className={'item'}>
                                        <h6>{translation("ASSISTA_Generic_Form_Field_Email")}</h6>
                                        <p>{formikProps.values['assistaCompanyWitness2Email']}</p>
                                    </div>
                                </>
                            }
                        </>
                    }
                </>
            }
            <LineComponent/>
        </div>
    )
}