import React, {useCallback, useState} from 'react';
import {ErrorMessage, Field, useFormikContext} from 'formik';
import {extensions} from "../../../common/functions";
import {useDropzone} from "react-dropzone";
import RadioComponent from "../../../common/Radio";
import InputComponent from "../../../common/Input";
import BusinessLegalProtection4 from "../subcomponents/businessLegalProtection/step/BusinessLegalProtection4";

export default function AssistaStep4({translation}) {
    const formikProps = useFormikContext();

    const choices1 = [
        translation('choice1'),
        translation('choice3')
    ];

    const choices2 = [
        translation('choice4'),
        translation('choice5'),
        translation('choice6')
    ];

    const choices3 = [
        translation('choice7')
    ];

    const acceptedFormats = {
        'image/jpg': [],
        'image/jpeg': [],
        'image/gif': [],
        'image/png': [],
        'application/pdf': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    }

    const displayExtensions = () => {
        let authorizedExtensions = translation('authorizedExtensionLabel');
        extensions.forEach((extension, index) => {
            authorizedExtensions += extension + (index !== extensions.length - 1 ? ', ' : '');
        })
        return authorizedExtensions;
    }

    const displayMaxSize = () => {
        return translation('maxSizeLabel') + "5MB";
    }

    const onDrop = useCallback(acceptedFiles => {
        formikProps.setFieldValue('assistaDocument', [...acceptedFiles, ...formikProps.values['assistaDocument']]);
    })

    const {fileRejections, getRootProps, getInputProps} = useDropzone({accept: acceptedFormats, onDrop: onDrop, maxSize: 5000000})

    const removeFile = (name) => {
        const validFileIndex = formikProps.values['assistaDocument'].findIndex(e => e.name === name)
        formikProps.values['assistaDocument'].splice(validFileIndex, 1);
        formikProps.setFieldValue('assistaDocument',[...formikProps.values['assistaDocument']]);
    }

    const humanFileSize = (size) => {
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    const displayFiles = formikProps.values['assistaDocument'].map((file, index) => (
        <div className={'acceptedFile'} key={index}>
            <div className={'acceptedFileName'}>
                <div>{file.name}</div>
                <div><b>{humanFileSize(file.size)}</b></div>
            </div>
            <button
                className={'button'}
                type="button"
                onClick={() => removeFile(file)}>
                {translation('deleteFileLabel')}
            </button>
        </div>
    ))

    const forbiddenFiles = fileRejections.map(({ file, errors }, index) => (
        <div className={'acceptedFile'} key={"error-" + index}>
            <div className={'acceptedFileName forbiddenFile'}>
                <div>{file.name}</div>
                <div><b>{humanFileSize(file.size)}</b></div>
            </div>
            <div className={'forbiddenFile'}>{translation('wrongExtensionAndFileTooBigLabel')}</div>
        </div>
    ));

    const updateOtherProtection = (event) => {
        formikProps.setFieldValue('assistaOtherProtection', event.currentTarget.value);
        if (event.currentTarget.value === 'no') {
            formikProps.setFieldValue('assistaProtectionName', '');
            formikProps.setFieldValue('assistaCaseReported', '');
        }
    }

    return (
        <>
            {formikProps.values['assistaFormChoice'] === translation('choice8') &&
                <BusinessLegalProtection4 translation={translation}/>
            }
            {formikProps.values['assistaFormChoice'] !== translation('choice8') &&
                <>
                    <div className={'item'}>
                        <h4>
                            {translation("ASSISTA_Generic_Form_Label_Step4")}
                        </h4>
                    </div>

                    <div className={'item'}>
                        {choices1.includes(formikProps.values['assistaFormChoice']) &&
                            <p>{translation('uploadDocumentsLabel')} {translation('uploadDocumentsDetails1Label')}.</p>
                        }
                        {(choices2.includes(formikProps.values['assistaFormChoice'])
                                || choices3.includes(formikProps.values['assistaFormChoice']))  &&
                            <p>{translation('uploadDocumentsLabel')} {translation('uploadDocumentsDetails2Label')}.</p>
                        }
                        <p>{displayExtensions()}</p>
                        <p>{displayMaxSize()}</p>
                        <div className={'dropContainer'} {...getRootProps()}>
                            <input name='assistaDocument' {...getInputProps()} />
                            <p>{translation('dragndropLabel')}</p>
                        </div>
                        <aside>
                            {formikProps.values['assistaDocument'] && formikProps.values['assistaDocument'].length > 0 &&
                                <div className={'acceptedFiles'}>
                                    {displayFiles}
                                </div>
                            }
                            <div className={'acceptedFiles'}>
                                {forbiddenFiles}
                            </div>
                        </aside>
                        <ErrorMessage className="text-danger" name='assistaDocument' component="div" />
                    </div>

                    <div className={'item'}>
                        <h5>{translation("ASSISTA_Generic_Form_Step4_Field_Yours_Insurances")}</h5>
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation("ASSISTA_Generic_Form_Step4_Field_Other_Protection") + '*'}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaOtherProtection1',
                                value: translation('noLabel'),
                                name: 'assistaOtherProtection',
                                label: translation('noLabel')
                            }}
                            change={updateOtherProtection}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaOtherProtection2',
                                value: translation('yesLabel'),
                                name: 'assistaOtherProtection',
                                label: translation('yesLabel')
                            }}
                            change={updateOtherProtection}
                        />
                        <ErrorMessage className="text-danger" name='assistaOtherProtection' component="div" />
                    </div>

                    {formikProps.values['assistaOtherProtection'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaProtectionName',
                                        label: translation("ASSISTA_Generic_Form_Step4_Field_If_Yes") + '*',
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Generic_Form_Step4_Field_Case_Reported")}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCaseReported2',
                                        value: translation('yesLabel'),
                                        name: 'assistaCaseReported',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCaseReported1',
                                        value: translation('noLabel'),
                                        name: 'assistaCaseReported',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCaseReported' component="div" />
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h6>
                            {translation('authorizationLabel') + '*'}
                        </h6>
                        <div className={'form-check'}>
                            <label className={'form-label'}>
                                <Field
                                    id='assistaAuthorization'
                                    name='assistaAuthorization'
                                    className={'form-check-input'}
                                    type='checkbox'
                                />
                                {translation('authorizationText')}
                            </label>
                            <ErrorMessage className="text-danger" name='assistaAuthorization' component="div" />
                        </div>
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation('processingDataLabel')}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <p>
                            {translation('privacyPolicyText')}
                        </p>
                        <p>
                            {translation('privacyPolicyText2')}
                            <a href={translation('privacyPolicyTextLink')} target="_blank" rel="noopener noreferrer">{translation('privacyPolicyText3')}</a>.
                            {translation('privacyPolicyText4')}
                        </p>
                    </div>
                </>
            }
        </>
    )
}