import React from "react";
import {useFormikContext} from "formik";
import LineComponent from "../../../common/ui/Line";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import BusinessLegalProtection2 from "../subcomponents/businessLegalProtection/summary/BusinessLegalProtection2";
import BusinessLegalProtection3 from "../subcomponents/businessLegalProtection/summary/BusinessLegalProtection3";
import BusinessLegalProtection4 from "../subcomponents/businessLegalProtection/summary/BusinessLegalProtection4";
import { PDFExport } from "@progress/kendo-react-pdf";
import { defineFont } from '@progress/kendo-drawing/pdf';

defineFont({
    'DejaVu Sans' : 'https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf'
});

export default function SummaryComponent({translation}) {
    const formikProps = useFormikContext();
    const printRef = React.useRef();

    const printRefBusinessLegalProtection1 = React.useRef();
    const printRefBusinessLegalProtection2 = React.useRef();
    const printRefBusinessLegalProtection3 = React.useRef();
    const printRefBusinessLegalProtection4 = React.useRef();

    const pdfExportComponent = React.useRef(null);

    const choices1 = [
        translation('choice3')
    ];

    const choices2 = [
        translation('choice4'),
        translation('choice5'),
        translation('choice6')
    ];

    const choices3 = [
        translation('choice7')
    ];

    const choices4 = [
        translation('choice1')
    ];

    const downloadPDF = async () => {
        if (formikProps.values['assistaFormChoice'] !== translation('choice8')) {
            const pdf = new jsPDF('p', 'mm');
            const element = printRef.current;
            const canvas = await html2canvas(element);
            const data = canvas.toDataURL('image/jpg');

            const imgProperties = pdf.getImageProperties(data);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;

            pdf.addImage(data, 'PNG', 10, 10, pdfWidth-20, pdfHeight-20);
        } else {
            pdfExportComponent.current.save();
        }
    };

    return (
        <>
            {formikProps.values['assistaFormChoice'] === translation('choice8') &&
                <>
                    <div className={'item'}>
                        <h4>{translation('step5Label')}</h4>
                    </div>
                    <p>{translation('step5LabelText')}</p>
                    <PDFExport paperSize="A4" margin="2cm" ref={pdfExportComponent} scale={0.5}>
                        <div className={"pdf-kendo"}>
                            <div className={'item'}>
                                <h5>{translation("ASSISTA_Generic_Form_Label_Step1")}</h5>
                            </div>

                            <div className={'item'}>
                                <h6>{translation('kindDamageLabel')}</h6>
                                <p>{formikProps.values['assistaFormChoice']}</p>
                            </div>
                            <LineComponent/>
                            <BusinessLegalProtection2 translation={translation} printRef={printRefBusinessLegalProtection2}/>
                            <BusinessLegalProtection3 translation={translation} printRef={printRefBusinessLegalProtection3}/>
                            <BusinessLegalProtection4 translation={translation} printRef={printRefBusinessLegalProtection4}/>
                        </div>
                    </PDFExport>
                </>
            }
            {formikProps.values['assistaFormChoice'] !== translation('choice8') &&
                <>
                    <div ref={printRef}>
                        <div className={'item'}>
                            <h4>{translation('step5Label')}</h4>
                        </div>

                        <p>{translation('step5LabelText')}</p>

                        <LineComponent/>

                        <div className={'item'}>
                            <h5>{translation("ASSISTA_Generic_Form_Label_Step1")}</h5>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('kindDamageLabel')}</h6>
                            <p>{formikProps.values['assistaFormChoice']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('damageReasonLabel')}</h6>
                            <p>{formikProps.values['assistaReasonDamage']}</p>
                        </div>

                        <LineComponent/>

                        <div className={'item'}>
                            <h5>{translation("step2Label")}</h5>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('policeNumberLabel')}</h6>
                            <p>{formikProps.values['assistaPoliceNumber']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('companyNameLabel')}</h6>
                            <p>{formikProps.values['assistaCompanyName']}</p>
                        </div>

                        <div className={'item-names'}>
                            <div>
                                <h6>{translation('firstnameLabel')}</h6>
                                <p>{formikProps.values['assistaFirstname']}</p>
                            </div>
                            <div>
                                <h6>{translation('surnameLabel')}</h6>
                                <p>{formikProps.values['assistaSurname']}</p>
                            </div>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('birthdayLabel')}</h6>
                            <p>{formikProps.values['assistaBirthday']}</p>
                        </div>

                        <div className={'item'}>
                            <div>
                                <h6>{translation('addressLabel')}</h6>
                                <p>{formikProps.values['assistaAddress']}</p>
                            </div>
                        </div>

                        <div className={'item-street'}>
                            <div>
                                <h6>{translation('npaLabel')}</h6>
                                <p>{formikProps.values['assistaAddressNPA']}</p>
                            </div>
                            <div>
                                <h6>{translation('cityLabel')}</h6>
                                <p>{formikProps.values['assistaAddressCity']}</p>
                            </div>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('landLabel')}</h6>
                            <p>{formikProps.values['assistaLand']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('phoneNumberLabel')}</h6>
                            <p>{formikProps.values['assistaPhone']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('phoneNumberBisLabel')}</h6>
                            <p>{formikProps.values['assistaPhoneBis']}</p>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('emailLabel')}</h6>
                            <p>{formikProps.values['assistaEmail']}</p>
                        </div>

                        <LineComponent/>

                        <div className={'item'}>
                            <h6>{translation('holderInformationLabel')}</h6>
                        </div>

                        <div className={'item-names'}>
                            <div>
                                <h6>{translation('firstnameLabel')}</h6>
                                <p>{formikProps.values['assistaHolderFirstname']}</p>
                            </div>
                            <div>
                                <h6>{translation('surnameLabel')}</h6>
                                <p>{formikProps.values['assistaHolderSurname']}</p>
                            </div>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('holderLabel')}</h6>
                            <p>{formikProps.values['assistaHolderCompany']}</p>
                        </div>

                        {(choices4.includes(formikProps.values['assistaFormChoice']) && formikProps.values['assistaReasonDamage'] === translation('incidentLabel')) &&
                            <>
                                <div className={'item'}>
                                    <h6>{translation('birthdayLabel')}</h6>
                                    <p>{formikProps.values['assistaHolderBirthday']}</p>
                                </div>

                                <div className={'item'}>
                                    <div>
                                        <h6>{translation('addressLabel')}</h6>
                                        <p>{formikProps.values['assistaHolderAddress']}</p>
                                    </div>
                                </div>

                                <div className={'item-street'}>
                                    <div>
                                        <h6>{translation('npaLabel')}</h6>
                                        <p>{formikProps.values['assistaHolderAddressNPA']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('cityLabel')}</h6>
                                        <p>{formikProps.values['assistaHolderAddressCity']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('phoneNumberLabel')}</h6>
                                    <p>{formikProps.values['assistaHolderPhone']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('emailLabel')}</h6>
                                    <p>{formikProps.values['assistaHolderEmail']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('holderConnectionLabel')}</h6>
                                    <p>{formikProps.values['assistaHolderConnection']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('otherInsuredPersonLabel')}</h6>
                                    <p>{formikProps.values['assistaOtherInsuredPerson']}</p>
                                </div>

                                {formikProps.values['assistaOtherInsuredPerson'] === translation('yesLabel') &&
                                    <>
                                        <div className={'item-names'}>
                                            <div>
                                                <h6>{translation('firstnameLabel')}</h6>
                                                <p>{formikProps.values['assistaOtherInsuredPersonFirstname']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation('surnameLabel')}</h6>
                                                <p>{formikProps.values['assistaOtherInsuredPersonSurname']}</p>
                                            </div>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('birthdayLabel')}</h6>
                                            <p>{formikProps.values['assistaOtherInsuredPersonBirthday']}</p>
                                        </div>

                                        <div className={'item'}>
                                            <div>
                                                <h6>{translation('addressLabel')}</h6>
                                                <p>{formikProps.values['assistaOtherInsuredPersonAddress']}</p>
                                            </div>
                                        </div>

                                        <div className={'item-street'}>
                                            <div>
                                                <h6>{translation('npaLabel')}</h6>
                                                <p>{formikProps.values['assistaOtherInsuredPersonAddressNPA']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation('cityLabel')}</h6>
                                                <p>{formikProps.values['assistaOtherInsuredPersonAddressCity']}</p>
                                            </div>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('phoneNumberSimpleLabel')}</h6>
                                            <p>{formikProps.values['assistaOtherInsuredPersonPhone']}</p>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('emailLabel')}</h6>
                                            <p>{formikProps.values['assistaOtherInsuredPersonEmail']}</p>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('holderConnectionLabel')}</h6>
                                            <p>{formikProps.values['assistaOtherInsuredPersonConnection']}</p>
                                        </div>
                                    </>
                                }

                                <div className={'item'}>
                                    <h5>{translation('paymentLabel')}</h5>
                                </div>

                                <div className={'item-names'}>
                                    <div>
                                        <h6>{translation('accountInstitutionLabel')}</h6>
                                        <p>{formikProps.values['assistaBankAccountPaymentInstitution']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('accountIbanLabel')}</h6>
                                        <p>{formikProps.values['assistaBankAccountPaymentIban']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('accountHolderLabel')}</h6>
                                    <p>{formikProps.values['assistaBankAccountPaymentHolder']}</p>
                                </div>
                            </>
                        }

                        <LineComponent/>

                        <div className={'item'}>
                            <h5>{translation("ASSISTA_Generic_Form_Label_Step3")}</h5>
                        </div>

                        {choices1.includes(formikProps.values['assistaFormChoice']) &&
                            <>
                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Step3_H3_Vehicle_Informations")}</h6>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Brand")}</h6>
                                    <p>{formikProps.values['assistaMark']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Plate")}</h6>
                                    <p>{formikProps.values['assistaModel']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Date")}</h6>
                                    <p>{formikProps.values['assistaDateDamage']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('exactPlaceLabel')}</h6>
                                    <p>{formikProps.values['assistaExactPlace']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('landLabel')}</h6>
                                    <p>{formikProps.values['assistaExactPlaceLand']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('whatHappenedLabel')}</h6>
                                    <p>{formikProps.values['assistaExactPlaceDescription']}</p>
                                </div>
                            </>
                        }

                        {choices4.includes(formikProps.values['assistaFormChoice']) &&
                            <>
                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Step3_H3_Vehicle_Informations")}</h6>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Date")}</h6>
                                    <p>{formikProps.values['assistaDateDamage']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Description")}</h6>
                                    <p>{formikProps.values['assistaCaseDamage']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_H3_Damage_Amount")}</h6>
                                    <p>{formikProps.values['assistaCompensation']}</p>
                                </div>

                                {formikProps.values['assistaReasonDamage'] === translation('incidentLabel') &&
                                    <>
                                        <div className={'item'}>
                                            <h6>{translation('accidentReportMadeLabel')}</h6>
                                            <p>{formikProps.values['assistaAccidentReportMade']}</p>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('policeThereLabel')}</h6>
                                            <p>{formikProps.values['assistaPoliceThere']}</p>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('injuredPeopleLabel')}</h6>
                                            <p>{formikProps.values['assistaInjuredPeople']}</p>
                                        </div>

                                        {formikProps.values['assistaInjuredPeople'] === translation('yesLabel') &&
                                            <div className={'item'}>
                                                <h6>{translation('injuredPeopleInformationLabel')}</h6>
                                                <p>{formikProps.values['assistaInjuredPeopleInformation']}</p>
                                            </div>
                                        }

                                        <div className={'item'}>
                                            <h6>{translation('accidentInsuranceReportedLabel')}</h6>
                                            <p>{formikProps.values['assistaAccidentInsuranceReported']}</p>
                                        </div>

                                        <div className={'item-names'}>
                                            <div>
                                                <h6>{translation("ASSISTA_Generic_Form_Field_Brand")}</h6>
                                                <p>{formikProps.values['assistaMark']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation("ASSISTA_Generic_Form_Field_Plate")}</h6>
                                                <p>{formikProps.values['assistaModel']}</p>
                                            </div>
                                        </div>

                                        <div className={'item-names'}>
                                            <div>
                                                <h6>{translation('firstDatePlacingMarketLabel')}</h6>
                                                <p>{formikProps.values['assistaFirstDatePlacingMarket']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation('kilometerStatusLabel')}</h6>
                                                <p>{formikProps.values['assistaKilometerStatus']}</p>
                                            </div>
                                        </div>

                                        <div className={'item'}>
                                            <h5>{translation("ASSISTA_Generic_Form_Step4_Field_Yours_Insurances")}</h5>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation("ASSISTA_Generic_Form_Step3_Field_Vehicle_Insurance")}</h6>
                                            <p>{formikProps.values['assistaVehicleInsurance']}</p>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('comprehensiveInsuranceLabel')}</h6>
                                            <p>{formikProps.values['assistaComprehensiveInsurance']}</p>
                                        </div>

                                        {formikProps.values['assistaComprehensiveInsurance'] === translation('yesLabel') &&
                                            <>
                                                <div className={'item'}>
                                                    <p>{formikProps.values['assistaComprehensiveLevel']}</p>
                                                </div>

                                                <div className={'item'}>
                                                    <h6>{translation('deductibleLabel')}</h6>
                                                    <p>{formikProps.values['assistaDeductibleAmount']}</p>
                                                </div>

                                                <div className={'item'}>
                                                    <h6>{translation('reportedDamageLabel')}</h6>
                                                    <p>{formikProps.values['assistaReportedDamage']}</p>
                                                </div>
                                            </>
                                        }

                                        <div className={'item'}>
                                            <h5>{translation('vehicleOwnerLabel')}</h5>
                                        </div>

                                        <div className={'item-names'}>
                                            <div>
                                                <h6>{translation('firstnameLabel')}</h6>
                                                <p>{formikProps.values['assistaVehicleOwnerFirstname']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation('surnameLabel')}</h6>
                                                <p>{formikProps.values['assistaVehicleOwnerSurname']}</p>
                                            </div>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('counterpartyLabel')}</h6>
                                            <p>{formikProps.values['assistaVehicleOwnerCompany']}</p>
                                        </div>


                                        <div className={'item'}>
                                            <h6>{translation('addressLabel')}</h6>
                                            <p>{formikProps.values['assistaVehicleOwnerAddress']}</p>
                                        </div>

                                        <div className={'item-street'}>
                                            <div>
                                                <h6>{translation('npaLabel')}</h6>
                                                <p>{formikProps.values['assistaVehicleOwnerAddressNPA']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation('cityLabel')}</h6>
                                                <p>{formikProps.values['assistaVehicleOwnerAddressCity']}</p>
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className={'item'}>
                                    <h5>{translation("ASSISTA_Generic_Form_Step3_H3_Counter_Party")}</h5>
                                </div>

                                <div className={'item-names'}>
                                    <div>
                                        <h6>{translation('firstnameLabel')}</h6>
                                        <p>{formikProps.values['assistaIncidentCounterPartyFirstname']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('surnameLabel')}</h6>
                                        <p>{formikProps.values['assistaIncidentCounterPartyName']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <div>
                                        <h6>{translation('companyLabel')}</h6>
                                        <p>{formikProps.values['assistaIncidentCounterPartyCompany']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('addressLabel')}</h6>
                                    <p>{formikProps.values['assistaIncidentCounterPartyAddress']}</p>
                                </div>

                                <div className={'item-street'}>
                                    <div>
                                        <h6>{translation('npaLabel')}</h6>
                                        <p>{formikProps.values['assistaIncidentCounterPartyAddressNPA']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('cityLabel')}</h6>
                                        <p>{formikProps.values['assistaIncidentCounterPartyAddressCity']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('landLabel')}</h6>
                                    <p>{formikProps.values['assistaIncidentCounterPartyAddressLand']}</p>
                                </div>

                                {formikProps.values['assistaReasonDamage'] === translation('incidentLabel') &&
                                    <>
                                        <div className={'item'}>
                                            <h5>{translation('informationsOtherPartyLabel')}</h5>
                                        </div>

                                        <div className={'item-names'}>
                                            <div>
                                                <h6>{translation("ASSISTA_Generic_Form_Field_Brand")}</h6>
                                                <p>{formikProps.values['assistaOtherPartyMark']}</p>
                                            </div>
                                            <div>
                                                <h6>{translation("ASSISTA_Generic_Form_Field_Plate")}</h6>
                                                <p>{formikProps.values['assistaOtherPartyModel']}</p>
                                            </div>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('otherPartyLiabilityInsuranceLabel')}</h6>
                                            <p>{formikProps.values['assistaOtherPartyLiabilityInsurance']}</p>
                                        </div>

                                        <div className={'item'}>
                                            <h6>{translation('vehicleOwnerDifferentLabel')}</h6>
                                            <p>{formikProps.values['assistaVehicleOwnerDifferent']}</p>
                                        </div>

                                        {formikProps.values['assistaVehicleOwnerDifferent'] === translation('yesLabel') &&
                                            <>
                                                <div className={'item-names'}>
                                                    <div>
                                                        <h6>{translation('firstnameLabel')}</h6>
                                                        <p>{formikProps.values['assistaVehicleOwnerDifferentFirstname']}</p>
                                                    </div>
                                                    <div>
                                                        <h6>{translation('surnameLabel')}</h6>
                                                        <p>{formikProps.values['assistaVehicleOwnerDifferentName']}</p>
                                                    </div>
                                                </div>

                                                <div className={'item'}>
                                                    <h6>{translation('addressLabel')}</h6>
                                                    <p>{formikProps.values['assistaVehicleOwnerDifferentAddress']}</p>
                                                </div>

                                                <div className={'item-street'}>
                                                    <div>
                                                        <h6>{translation('npaLabel')}</h6>
                                                        <p>{formikProps.values['assistaVehicleOwnerDifferentAddressNPA']}</p>
                                                    </div>
                                                    <div>
                                                        <h6>{translation('cityLabel')}</h6>
                                                        <p>{formikProps.values['assistaVehicleOwnerDifferentAddressCity']}</p>
                                                    </div>
                                                </div>

                                                <div className={'item'}>
                                                    <h6>{translation('landLabel')}</h6>
                                                    <p>{formikProps.values['assistaVehicleOwnerDifferentAddressLand']}</p>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                                {formikProps.values['assistaReasonDamage'] === translation('otherDisputeLabel') &&
                                    <>
                                        <div className={'item'}>
                                            <h6>{translation('anotherCounterPartyLabel')}</h6>
                                            <p>{formikProps.values['assistaAnotherCounterParty']}</p>
                                        </div>

                                        {formikProps.values['assistaAnotherCounterParty'] === translation('yesLabel') &&
                                            <>
                                                <div className={'item-names'}>
                                                    <div>
                                                        <h6>{translation('firstnameLabel')}</h6>
                                                        <p>{formikProps.values['assistaAnotherCounterPartyFirstname']}</p>
                                                    </div>
                                                    <div>
                                                        <h6>{translation('surnameLabel')}</h6>
                                                        <p>{formikProps.values['assistaAnotherCounterPartyName']}</p>
                                                    </div>
                                                </div>

                                                <div className={'item'}>
                                                    <h6>{translation('addressLabel')}</h6>
                                                    <p>{formikProps.values['assistaAnotherCounterPartyAddress']}</p>
                                                </div>

                                                <div className={'item-street'}>
                                                    <div>
                                                        <h6>{translation('npaLabel')}</h6>
                                                        <p>{formikProps.values['assistaAnotherCounterPartyAddressNPA']}</p>
                                                    </div>
                                                    <div>
                                                        <h6>{translation('cityLabel')}</h6>
                                                        <p>{formikProps.values['assistaAnotherCounterPartyAddressCity']}</p>
                                                    </div>
                                                </div>

                                                <div className={'item'}>
                                                    <h6>{translation('landLabel')}</h6>
                                                    <p>{formikProps.values['assistaAnotherCounterPartyAddressLand']}</p>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }

                        {choices2.includes(formikProps.values['assistaFormChoice']) &&
                            <>
                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Date")}</h6>
                                    <p>{formikProps.values['assistaDateDamage']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{formikProps.values['damageCauseLabel']}</h6>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Description")}</h6>
                                    <p>{formikProps.values['assistaCaseDamage']}</p>
                                </div>
                            </>
                        }

                        {choices3.includes(formikProps.values['assistaFormChoice']) &&
                            <>
                                <h6>{translation('buildingInformationLabel')}</h6>

                                <h6>{translation('addressLabel')}</h6>
                                <p>{formikProps.values['assistaBuildingAddress']}</p>

                                <h6>{translation('npaLabel')}</h6>
                                <p>{formikProps.values['assistaBuildingAddressNPA']}</p>

                                <h6>{translation('cityLabel')}</h6>
                                <p>{formikProps.values['assistaBuildingAddressCity']}</p>

                                <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Date")}</h6>
                                <p>{formikProps.values['assistaDateDamage']}</p>

                                <h6>{translation("ASSISTA_Generic_Form_Field_Damage_Description")}</h6>
                                <p>{formikProps.values['assistaCaseDamage']}</p>
                            </>
                        }

                        {!choices4.includes(formikProps.values['assistaFormChoice']) &&
                            <>
                                <LineComponent/>
                                <div className={'item-names'}>
                                    <div>
                                        <h6>{translation('firstnameLabel')}</h6>
                                        <p>{formikProps.values['assistaCounterpartyFirstname']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('surnameLabel')}</h6>
                                        <p>{formikProps.values['assistaCounterpartyName']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <div>
                                        <h6>{translation('holderLabel')}</h6>
                                        <p>{formikProps.values['assistaCounterpartyCompany']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <div>
                                        <h6>{translation('addressLabel')}</h6>
                                        <p>{formikProps.values['assistaCounterpartyAddress']}</p>
                                    </div>
                                </div>

                                <div className={'item-street'}>
                                    <div>
                                        <h6>{translation('npaLabel')}</h6>
                                        <p>{formikProps.values['assistaCounterpartyAddressNPA']}</p>
                                    </div>
                                    <div>
                                        <h6>{translation('cityLabel')}</h6>
                                        <p>{formikProps.values['assistaCounterpartyAddressCity']}</p>
                                    </div>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation('landLabel')}</h6>
                                    <p>{formikProps.values['assistaCounterpartyAddressLand']}</p>
                                </div>
                            </>
                        }

                        <LineComponent/>

                        <div className={'item'}>
                            <h5>{translation("ASSISTA_Generic_Form_Label_Step4")}</h5>
                        </div>

                        <div className={'item'}>
                            <h6>{translation('stepper4Label')}</h6>
                            {formikProps.values['assistaDocument'] !== '' &&
                                <>
                                    {formikProps.values['assistaDocument'].map((file, index) => (
                                        <p key={index}>{file.name}</p>
                                    ))}
                                </>
                            }
                        </div>

                        <LineComponent/>

                        <div className={'item'}>
                            <h6>{translation("ASSISTA_Generic_Form_Step4_Field_Yours_Insurances")}</h6>
                        </div>

                        <div className={'item'}>
                            <h6>{translation("ASSISTA_Generic_Form_Step4_Field_Other_Protection")}</h6>
                            <p>{formikProps.values['assistaOtherProtection']}</p>
                        </div>

                        {formikProps.values['assistaOtherProtection'] === translation('yesLabel') &&
                            <>
                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Step4_Field_If_Yes")}</h6>
                                    <p>{formikProps.values['assistaProtectionName']}</p>
                                </div>

                                <div className={'item'}>
                                    <h6>{translation("ASSISTA_Generic_Form_Step4_Field_Case_Reported")}</h6>
                                    <p>{formikProps.values['assistaCaseReported']}</p>
                                </div>
                            </>
                        }
                    </div>

                    <LineComponent/>
                </>
            }
            <div>
                <button
                    className={'button'}
                    onClick={downloadPDF}
                    type="button">
                    {translation("ASSISTA_Generic_Form_Step5_Button_Download")}
                </button>
            </div>
        </>
    )
}