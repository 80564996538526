import React from "react";
import InputComponent from "../../../../../common/Input";
import RadioComponent from "../../../../../common/Radio";
import {ErrorMessage, useFormikContext} from "formik";

export default function BusinessLegalProtection2({translation}) {
    const formikProps = useFormikContext();

    return (
        <>
            <div className={'item'}>
                <h4>
                    {translation("ASSISTA_Generic_Form_Label_Step2")}
                </h4>
            </div>

            <div className={'item'}>
                <p>
                    {translation("ASSISTA_Company_Form_Step2_Text_Company_Information")}
                </p>
            </div>

            <div className={'item'}>
                <InputComponent data={{name: 'assistaPoliceNumber', label: translation("ASSISTA_Generic_Form_Field_Police_Number") + '*'}}/>
            </div>

            <div className={'item'}>
                <InputComponent data={{name: 'assistaInsurer', label: translation('ASSISTA_Company_Form_Step2_Field_Which_Partner')}}/>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaCompanyName',
                        label: translation("ASSISTA_Company_Form_Step2_Field_Company_Name") + "*",
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaCompanyAddress',
                        label: translation("ASSISTA_Generic_Form_Field_Street_Number") + "*",
                        inputMode: "text",
                        component: "textarea",
                        rows: "3"
                    }}
                />
            </div>

            <div className={'item-street'}>
                <InputComponent
                    data={{
                        name: 'assistaCompanyAddressNPA',
                        label: translation("ASSISTA_Generic_Form_Field_NPA") + "*",
                        inputMode: "numeric"
                    }}
                />
                <InputComponent
                    data={{
                        name: 'assistaCompanyAddressCity',
                        label: translation("ASSISTA_Generic_Form_Field_Location") + "*",
                        className: "city",
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <h5>
                    {translation("ASSISTA_Company_Form_Step2_H2_Information_Contact")}
                </h5>
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation("ASSISTA_Generic_Form_Label_Gender") + "*"}
                </label>
                <RadioComponent
                    data={{id: 'assistaGender1', value: 'male', name: 'assistaGender', label: translation("ASSISTA_Generic_Form_Field_Gender_Male")}}/>
                <RadioComponent
                    data={{id: 'assistaGender2', value: 'female', name: 'assistaGender', label: translation("ASSISTA_Generic_Form_Field_Gender_Female")}}/>
                <RadioComponent
                    data={{id: 'assistaGender3', value: 'other', name: 'assistaGender', label: translation("ASSISTA_Generic_Form_Field_Gender_Other")}}/>
                <ErrorMessage className="text-danger" name='assistaGender' component="div" />
            </div>

            <div className={'item-names'}>
                <InputComponent
                    data={{
                        name: 'assistaFirstname',
                        label: translation("ASSISTA_Generic_Form_Field_Firstname") + "*",
                        inputMode: "text"
                    }}
                />
                <InputComponent
                    data={{
                        name: 'assistaSurname',
                        label: translation("ASSISTA_Generic_Form_Field_Surename") + "*",
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaCompanyJob',
                        label: translation("ASSISTA_Company_Form_Field_Job") + "*",
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaPhone',
                        label: translation("ASSISTA_Generic_Form_Field_Phone_1") + "*",
                        inputMode: "tel"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaPhoneBis',
                        label: translation("ASSISTA_Generic_Form_Field_Phone_2"),
                        inputMode: "tel"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaEmail',
                        label: translation("ASSISTA_Generic_Form_Field_Email") + "*",
                        inputMode: "email"
                    }}
                />
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation("ASSISTA_Company_Form_Step2_Label_Who_Affected") + "*"}
                </label>
                <RadioComponent
                    data={{
                        id: 'assistaAffectedByDisaster1',
                        value: translation("ASSISTA_Company_Form_Step2_Field_Who_Affected_Company"),
                        name: 'assistaAffectedByDisaster',
                        label: translation("ASSISTA_Company_Form_Step2_Field_Who_Affected_Company")
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'assistaAffectedByDisaster2',
                        value: translation("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private"),
                        name: 'assistaAffectedByDisaster',
                        label: translation("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")
                    }}
                />
                <ErrorMessage className="text-danger" name='assistaAffectedByDisaster' component="div" />
            </div>

            {formikProps.values['assistaAffectedByDisaster'] === translation("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private") &&
                <>
                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Company_Form_Step2_H3_Information_Person_Needs_Protection")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation("ASSISTA_Generic_Form_Label_Gender") + "*"}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaPersonAffectedGender1',
                                value: 'male',
                                name: 'assistaPersonAffectedGender',
                                label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                            }}/>
                        <RadioComponent
                            data={{
                                id: 'assistaPersonAffectedGender2',
                                value: 'female',
                                name: 'assistaPersonAffectedGender',
                                label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                            }}/>
                        <RadioComponent
                            data={{
                                id: 'assistaPersonAffectedGender3',
                                value: 'other',
                                name: 'assistaPersonAffectedGender',
                                label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                            }}/>
                        <ErrorMessage className="text-danger" name="assistaPersonAffectedGender" component="div"/>
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedFirstname',
                                label: translation("ASSISTA_Generic_Form_Field_Firstname") + "*",
                                inputMode: "text"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedSurname',
                                label: translation("ASSISTA_Generic_Form_Field_Surename") + "*",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedAddress',
                                label: translation("ASSISTA_Generic_Form_Field_Street_Number") + "*",
                                inputMode: "text",
                                component: "textarea",
                                rows: "3"
                            }}
                        />
                    </div>

                    <div className={'item-street'}>
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedAddressNPA',
                                label: translation("ASSISTA_Generic_Form_Field_NPA") + "*",
                                inputMode: "numeric"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedAddressCity',
                                label: translation("ASSISTA_Generic_Form_Field_Location") + "*",
                                className: "city",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedLand',
                                label: translation("ASSISTA_Generic_Form_Field_Country") + "*",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedPhone',
                                label: translation("ASSISTA_Generic_Form_Field_Phone") + "*",
                                inputMode: "tel"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedEmail',
                                label: translation("ASSISTA_Generic_Form_Field_Email") + "*",
                                inputMode: "email"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPersonAffectedLink',
                                label: translation("ASSISTA_Company_Form_Step2_Field_Connection_With_Company") + "*",
                                inputMode: "text"
                            }}
                        />
                    </div>
                </>
            }

            <div className={'item'}>
                <h5>
                    {translation("ASSISTA_Generic_Form_H3_Bank_Details")}
                </h5>
            </div>

            <div className={'item'}>
                <p>
                    {translation("ASSISTA_Company_Form_Step2_Text_Account_Payment")}
                </p>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaBankAccountPaymentInstitution',
                        label: translation("ASSISTA_Generic_Form_Field_Financial_Institution") + "*",
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaBankAccountPaymentIban',
                        label: translation("ASSISTA_Generic_Form_Field_Iban") + "*",
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaBankAccountPaymentHolder',
                        label: translation("ASSISTA_Generic_Form_Field_Account_Holder") + "*",
                        inputMode: "text"
                    }}
                />
            </div>
        </>
    )
}