import * as Yup from "yup";
import I18n from "../../../../i18n";

export const validationSchemaBusinessLegalProtection2 = {
    ['assistaCompanyJob']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyAddress']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyAddressNPA']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')).required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyAddressCity']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaAffectedByDisaster']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedGender']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedFirstname']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedSurname']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedAddress']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedAddressNPA']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')).required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedAddressCity']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedLand']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedPhone']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedEmail']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaPersonAffectedLink']: Yup.string()
        .when(['assistaFormChoice', 'assistaAffectedByDisaster'], (assistaFormChoice, assistaAffectedByDisaster, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaAffectedByDisaster === I18n.t("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private")) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        })
}