import {initialValuesBusinessLegalProtection} from "./businessLegalProtection/businessLegalProtectionValues";

export default {
    ['assistaFormChoice']: '',
    ['assistaReasonDamage']: '',
    ['assistaInsurer']: '',
    ['assistaPoliceNumber']: '',
    ['assistaGender']: '',
    ['assistaCompanyName']: '',
    ['assistaFirstname']: '',
    ['assistaSurname']: '',
    ['assistaBirthday']: '',
    ['assistaAddress']: '',
    ['assistaAddressNPA']: '',
    ['assistaAddressCity']: '',
    ['assistaLand']: '',
    ['assistaPhone']: '',
    ['assistaPhoneBis']: '',
    ['assistaEmail']: '',
    ['assistaHolderGender']: '',
    ['assistaHolderFirstname']: '',
    ['assistaHolderSurname']: '',
    ['assistaHolderCompany']: '',
    ['assistaHolderBirthday']: '',
    ['assistaHolderAddress']: '',
    ['assistaHolderAddressNPA']: '',
    ['assistaHolderAddressCity']: '',
    ['assistaHolderPhone']: '',
    ['assistaHolderEmail']: '',
    ['assistaHolderConnection']: '',
    ['assistaOtherInsuredPerson']: '',
    ['assistaOtherInsuredPersonGender']: '',
    ['assistaOtherInsuredPersonFirstname']: '',
    ['assistaOtherInsuredPersonSurname']: '',
    ['assistaOtherInsuredPersonCompany']: '',
    ['assistaOtherInsuredPersonBirthday']: '',
    ['assistaOtherInsuredPersonAddress']: '',
    ['assistaOtherInsuredPersonAddressNPA']: '',
    ['assistaOtherInsuredPersonAddressCity']: '',
    ['assistaOtherInsuredPersonPhone']: '',
    ['assistaOtherInsuredPersonEmail']: '',
    ['assistaOtherInsuredPersonConnection']: '',
    ['assistaBankAccountPaymentInstitution']: '',
    ['assistaBankAccountPaymentIban']: '',
    ['assistaBankAccountPaymentHolder']: '',
    ['assistaAccidentReportMade']: '',
    ['assistaPoliceThere']: '',
    ['assistaInjuredPeople']: '',
    ['assistaInjuredPeopleInformation']: '',
    ['assistaAccidentInsuranceReported']: '',
    ['assistaFirstDatePlacingMarket']: '',
    ['assistaVehicleInsurance']: '',
    ['assistaKilometerStatus']: '',
    ['assistaComprehensiveInsurance']: '',
    ['assistaComprehensiveLevel']: '',
    ['assistaDeductibleAmount']: '',
    ['assistaReportedDamage']: '',
    ['assistaVehicleOwnerFirstname']: '',
    ['assistaVehicleOwnerSurname']: '',
    ['assistaVehicleOwnerCompany']: '',
    ['assistaVehicleOwnerAddress']: '',
    ['assistaVehicleOwnerAddressNPA']: '',
    ['assistaVehicleOwnerAddressCity']: '',
    ['assistaMark']: '',
    ['assistaModel']: '',
    ['assistaCaseDamage']: '',
    ['assistaBuildingAddress']: '',
    ['assistaBuildingAddressNPA']: '',
    ['assistaBuildingAddressCity']: '',
    ['assistaDateDamage']: '',
    ['assistaExactPlace']: '',
    ['assistaExactPlaceLand']: '',
    ['assistaExactPlaceDescription']: '',
    ['assistaCompensation']: '',
    ['assistaCounterpartyAddress']: '',
    ['assistaCounterpartyAddressNPA']: '',
    ['assistaCounterpartyAddressCity']: '',
    ['assistaCounterpartyAddressLand']: '',
    ['assistaCounterpartyName']: '',
    ['assistaCounterpartyFirstname']: '',
    ['assistaCounterpartyCompany']: '',
    ['assistaDocument']: [],
    ['assistaOtherProtection']: '',
    ['assistaProtectionName']: '',
    ['assistaCaseReported']: '',
    ['assistaAuthorization']: false,
    ['assistaIncidentCounterPartyName']: '',
    ['assistaIncidentCounterPartyFirstname']: '',
    ['assistaIncidentCounterPartyCompany']: '',
    ['assistaIncidentCounterPartyAddress']: '',
    ['assistaIncidentCounterPartyAddressNPA']: '',
    ['assistaIncidentCounterPartyAddressCity']: '',
    ['assistaIncidentCounterPartyAddressLand']: '',
    ['assistaVehicleOwnerDifferentName']: '',
    ['assistaVehicleOwnerDifferentFirstname']: '',
    ['assistaVehicleOwnerDifferentAddress']: '',
    ['assistaVehicleOwnerDifferentAddressNPA']: '',
    ['assistaVehicleOwnerDifferentAddressCity']: '',
    ['assistaVehicleOwnerDifferentAddressLand']: '',
    ['assistaAnotherCounterParty']: '',
    ['assistaAnotherCounterPartyName']: '',
    ['assistaAnotherCounterPartyCompany']: '',
    ['assistaAnotherCounterPartyFirstname']: '',
    ['assistaAnotherCounterPartyAddress']: '',
    ['assistaAnotherCounterPartyAddressNPA']: '',
    ['assistaAnotherCounterPartyAddressCity']: '',
    ['assistaAnotherCounterPartyAddressLand']: '',
    ['assistaOtherPartyMark']: '',
    ['assistaOtherPartyModel']: '',
    ['assistaOtherPartyLiabilityInsurance']: '',
    ...initialValuesBusinessLegalProtection
};