import {ErrorMessage, useFormikContext} from "formik";
import InputComponent from "../../../common/Input";
import React from "react";
import RadioComponent from "../../../common/Radio";
import CounterPartyItem from "../../../common/CounterPartyItem";

export default function AssistaSubStep4({translation}) {
    const formikProps = useFormikContext();

    const choices = [
        translation('choice1')
    ];

    function validateRequired(value) {
        let error;
        if (!value) {
            if (formikProps.values['assistaFormChoice'] !== ''
                && choices.includes(formikProps.values['assistaFormChoice'])) {
                error = translation('requiredDefaultMessage');
            }
        }
        return error;
    }

    const updateAnotherCounterParty = (event) => {
        formikProps.setFieldValue('assistaAnotherCounterParty', event.currentTarget.value);

        if (event.currentTarget.value === 'no') {
            formikProps.setFieldValue('assistaAnotherCounterPartyName', '');
            formikProps.setFieldValue('assistaAnotherCounterPartyFirstname', '');
            formikProps.setFieldValue('assistaAnotherCounterPartyAddress', '');
            formikProps.setFieldValue('assistaAnotherCounterPartyAddressNPA', '');
            formikProps.setFieldValue('assistaAnotherCounterPartyAddressCity', '');
            formikProps.setFieldValue('assistaAnotherCounterPartyAddressLand', '');
        }
    }

    const updateComprehensiveInsurance = (event) => {
        formikProps.setFieldValue('assistaComprehensiveInsurance', event.currentTarget.value);

        if (event.currentTarget.value === 'no') {
            formikProps.setFieldValue('assistaDeductibleAmount', '');
        }
    }

    const updateInjuredPeople = (event) => {
        formikProps.setFieldValue('assistaInjuredPeople', event.currentTarget.value);

        if (event.currentTarget.value === 'no') {
            formikProps.setFieldValue('assistaInjuredPeopleInformation', '');
        }
    }

    return(
        <>
            {formikProps.values['assistaReasonDamage'] === translation('incidentLabel') &&
                <>
                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaDateDamage',
                                label: translation("ASSISTA_Generic_Form_Field_Damage_Date") + '*',
                                type: "date"
                            }}
                            validate={validateRequired}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Damage_Cause")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCaseDamage',
                                label: translation("ASSISTA_Generic_Form_Field_Damage_Description") + '*',
                                component: "textarea"
                            }}
                            validate={validateRequired}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Damage_Amount")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompensation',
                                label: translation('chfAmountLabel') + '*'
                            }}
                            validate={validateRequired}
                        />
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('accidentReportMadeLabel')}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaAccidentReportMade2',
                                value: translation('yesLabel'),
                                name: 'assistaAccidentReportMade',
                                label: translation('yesLabel')
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaAccidentReportMade1',
                                value: translation('noLabel'),
                                name: 'assistaAccidentReportMade',
                                label: translation('noLabel')
                            }}
                        />
                        <ErrorMessage className="text-danger" name='assistaAccidentReportMade' component="div" />
                    </div>

                    {formikProps.values['assistaAccidentReportMade'] === translation('yesLabel') &&
                        <div className={'item'}>
                            <h6>{translation('accidentReportMadeInformationLabel')}</h6>
                        </div>
                    }

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('policeThereLabel')}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaPoliceThere2',
                                value: translation('yesLabel'),
                                name: 'assistaPoliceThere',
                                label: translation('yesLabel')
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaPoliceThere1',
                                value: translation('noLabel'),
                                name: 'assistaPoliceThere',
                                label: translation('noLabel')
                            }}
                        />
                        <ErrorMessage className="text-danger" name='assistaPoliceThere' component="div" />
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('injuredPeopleLabel')}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaInjuredPeople2',
                                value: translation('yesLabel'),
                                name: 'assistaInjuredPeople',
                                label: translation('yesLabel')
                            }}
                            change={updateInjuredPeople}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaInjuredPeople1',
                                value: translation('noLabel'),
                                name: 'assistaInjuredPeople',
                                label: translation('noLabel')
                            }}
                            change={updateInjuredPeople}
                        />
                        <ErrorMessage className="text-danger" name='assistaInjuredPeople' component="div" />
                    </div>

                    {formikProps.values['assistaInjuredPeople'] === translation('yesLabel') &&
                        <div className={'item'}>
                            <InputComponent
                                data={{
                                    name: 'assistaInjuredPeopleInformation',
                                    label: translation('injuredPeopleInformationLabel'),
                                    inputMode: "text"
                                }}
                            />
                        </div>
                    }

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('accidentInsuranceReportedLabel')}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaAccidentInsuranceReported2',
                                value: translation('yesLabel'),
                                name: 'assistaAccidentInsuranceReported',
                                label: translation('yesLabel')
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaAccidentInsuranceReported1',
                                value: translation('noLabel'),
                                name: 'assistaAccidentInsuranceReported',
                                label: translation('noLabel')
                            }}
                        />
                        <ErrorMessage className="text-danger" name='assistaAccidentInsuranceReported' component="div" />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_Step3_H3_Vehicle_Informations")}
                        </h5>
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaMark',
                                label: translation("ASSISTA_Generic_Form_Field_Brand"),
                                inputMode: "text"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaModel',
                                label: translation("ASSISTA_Generic_Form_Field_Plate"),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaFirstDatePlacingMarket',
                                label: translation('firstDatePlacingMarketLabel'),
                                type: "date"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaKilometerStatus',
                                label: translation('kilometerStatusLabel')
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation('insuranceLabel')}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaVehicleInsurance',
                                label: translation("ASSISTA_Generic_Form_Step3_Field_Vehicle_Insurance") + '*',
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('comprehensiveInsuranceLabel')}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaComprehensiveInsurance1',
                                value: translation('noLabel'),
                                name: 'assistaComprehensiveInsurance',
                                label: translation('noLabel')
                            }}
                            change={updateComprehensiveInsurance}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaComprehensiveInsurance2',
                                value: translation('yesLabel'),
                                name: 'assistaComprehensiveInsurance',
                                label: translation('yesLabel')
                            }}
                            change={updateComprehensiveInsurance}
                        />
                        <ErrorMessage className="text-danger" name='assistaComprehensiveInsurance' component="div" />
                    </div>

                    {formikProps.values['assistaComprehensiveInsurance'] === translation('yesLabel') &&
                        <>
                            <div className={'item'}>
                                <RadioComponent
                                    data={{
                                        id: 'assistaComprehensiveLevel1',
                                        value: translation('fullyComprehensiveLabel'),
                                        name: 'assistaComprehensiveLevel',
                                        label: translation('fullyComprehensiveLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaComprehensiveLevel2',
                                        value: translation('partiallyComprehensiveLabel'),
                                        name: 'assistaComprehensiveLevel',
                                        label: translation('partiallyComprehensiveLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaComprehensiveLevel' component="div" />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaDeductibleAmount',
                                        label: translation('deductibleLabel')
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation('reportedDamageLabel')}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaReportedDamage2',
                                        value: translation('yesLabel'),
                                        name: 'assistaReportedDamage',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReportedDamage1',
                                        value: translation('noLabel'),
                                        name: 'assistaReportedDamage',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaReportedDamage' component="div" />
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h5>
                            {translation('vehicleOwnerLabel')}
                        </h5>
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaVehicleOwnerFirstname',
                                label: translation('firstnameLabel'),
                                inputMode: "text"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaVehicleOwnerSurname',
                                label: translation('surnameLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaVehicleOwnerCompany',
                                label: translation('counterpartyLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaVehicleOwnerAddress',
                                label: translation('addressLabel'),
                                inputMode: "text",
                                component: "textarea",
                                rows: "3"
                            }}
                        />
                    </div>

                    <div className={'item-street'}>
                        <InputComponent
                            data={{
                                name: 'assistaVehicleOwnerAddressNPA',
                                label: translation('npaLabel'),
                                inputMode: "numeric"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaVehicleOwnerAddressCity',
                                label: translation('cityLabel'),
                                className: "city",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h3>{translation('informationsOtherPartyLabel')}</h3>
                    </div>

                    <CounterPartyItem translation={translation} prefix={"assistaIncidentCounterParty"} isRequired={false}/>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaOtherPartyMark',
                                label: translation("ASSISTA_Generic_Form_Field_Brand")
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaOtherPartyModel',
                                label: translation("ASSISTA_Generic_Form_Field_Plate")
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaOtherPartyLiabilityInsurance',
                                label: translation('otherPartyLiabilityInsuranceLabel')
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation('vehicleOwnerDifferentLabel')}
                        </h5>
                    </div>

                    <CounterPartyItem translation={translation} prefix={"assistaVehicleOwnerDifferent"} isRequired={false} titleLabel={false}/>
                </>
            }
            {formikProps.values['assistaReasonDamage'] === translation('otherDisputeLabel') &&
                <>
                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaDateDamage',
                                label: translation("ASSISTA_Generic_Form_Field_Damage_Date"),
                                type: "date"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation('litigationCauseLabel')}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCaseDamage',
                                label: translation("ASSISTA_Generic_Form_Field_Damage_Description") + '*',
                                component: "textarea"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Damage_Amount")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompensation',
                                label: translation('chfAmountLabel')
                            }}
                        />
                    </div>

                    <CounterPartyItem translation={translation} prefix={"assistaIncidentCounterParty"} isRequired={false}/>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('anotherCounterPartyLabel')}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaAnotherCounterParty1',
                                value: translation('noLabel'),
                                name: 'assistaAnotherCounterParty',
                                label: translation('noLabel')
                            }}
                            change={updateAnotherCounterParty}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaAnotherCounterParty2',
                                value: translation('yesLabel'),
                                name: 'assistaAnotherCounterParty',
                                label: translation('yesLabel')
                            }}
                            change={updateAnotherCounterParty}
                        />
                        <ErrorMessage className="text-danger" name='assistaAnotherCounterParty' component="div" />
                    </div>

                    {formikProps.values['assistaAnotherCounterParty'] === translation('yesLabel') &&
                        <CounterPartyItem
                            translation={translation}
                            prefix={"assistaAnotherCounterParty"}
                            isRequired={false}
                            translationLabel={"ASSISTA_Generic_Form_Field_Vehicle_Owner"}/>
                    }
                </>
            }
        </>
    )
}