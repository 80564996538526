import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';

import './Styles/assista/index.css'
import './Styles/assista/App.css'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

