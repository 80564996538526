import * as Yup from "yup";
import I18n from "../../../../i18n";

export const validationSchemaBusinessLegalProtection3 = {
    ['assistaCompanyLegalCase']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyTypeDispute']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyDateDamage']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyExactPlace']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyLand']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyDescriptionCaseDamage']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCompensation']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCounterPartyAddress']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice && (
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal") ||
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor") ||
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner")
            )) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCounterPartyAddressNPA']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice && (
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal") ||
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor") ||
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner")
            )) {
                return Yup.string().matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')).required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCounterPartyAddressCity']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice && (
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal") ||
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor") ||
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner")
            )) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCounterPartyAddressLand']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice && (
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal") ||
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor") ||
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner")
            )) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyAccidentReportMade']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanySignedAcknowledgment']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanySignedAcknowledgmentOpposingParty']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyPoliceHere']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyPoliceStation']: Yup.string(),
    ['assistaCompanyAccusedOf']: Yup.string(),
    ['assistaCompanyLicenseTakenAway']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyBloodTest']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCriminalMeasure']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyPoliceReportMade']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyPoliceHere'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyPoliceHere, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyPoliceHere === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCriminalMeasureDate']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyCriminalMeasure'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyCriminalMeasure, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyCriminalMeasure === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyPeopleInjured']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyPeopleInjuredInformations']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyPeopleInjured'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyPeopleInjured, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyPeopleInjured === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyDeclarationAccidentInsurance']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyPeopleInjured'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyPeopleInjured, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyPeopleInjured === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOtherPeopleInjured']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyPeopleInjured'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyPeopleInjured, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyPeopleInjured === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOtherPeopleInjuredInformations']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyOtherPeopleInjured'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyOtherPeopleInjured, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyOtherPeopleInjured === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOtherDeclarationAccidentInsurance']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyOtherPeopleInjured'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyOtherPeopleInjured, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyOtherPeopleInjured === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyMark']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyModel']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyFirstDatePlacingMarket']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyKilometerStatus']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')).required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyItemsDamaged']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyListItemsDamaged']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyItemsDamaged'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyItemsDamaged, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyItemsDamaged === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOwnerItemsAmountDamagedCompany']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyItemsDamaged'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyItemsDamaged, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyItemsDamaged === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOwnerItemsDamagedAddress']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyItemsDamaged'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyItemsDamaged, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyItemsDamaged === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOwnerItemsDamagedAddressNPA']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyItemsDamaged'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyItemsDamaged, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyItemsDamaged === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOwnerItemsDamagedAddressCity']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyItemsDamaged'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyItemsDamaged, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyItemsDamaged === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOwnerItemsDamagedAddressLand']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyItemsDamaged'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyItemsDamaged, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyItemsDamaged === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleAssessed']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleExpertName']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleAssessed'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleAssessed, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleAssessed === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleRepaired']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleRepairedBy']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleRepaired'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleRepaired, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleRepaired === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyNameInsuranceVehicle']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyPoliceNumberVehicle']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDeclaredIncident']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleCasco']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCascoPoliceNumber']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleCasco'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleCasco, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleCasco === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCascoFullOrPartial']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleCasco'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleCasco, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleCasco === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCascoFranchise']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleCasco'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleCasco, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleCasco === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleCascoDeclared']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleCasco'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleCasco, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleCasco === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleCompanyOwner']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleOwnerAddress']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleCompanyOwner'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleCompanyOwner, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleCompanyOwner === I18n.t('noLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleOwnerAddressNPA']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleCompanyOwner'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleCompanyOwner, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleCompanyOwner === I18n.t('noLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleOwnerAddressCity']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleCompanyOwner'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleCompanyOwner, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleCompanyOwner === I18n.t('noLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleOwnerAddressLand']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyVehicleCompanyOwner'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyVehicleCompanyOwner, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyVehicleCompanyOwner === I18n.t('noLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverGender']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverFirstname']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverSurname']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverAddress']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverAddressNPA']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverAddressCity']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverAddressLand']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverLicenseCategory']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyVehicleDriverLicenseDate']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCounterPartyMark']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCounterPartyModel']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyCounterPartyOwner']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyWitness']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase'], (assistaFormChoice, assistaCompanyLegalCase, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyWitness1Gender']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyWitness'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyWitness, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyWitness === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyWitness1Firstname']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyWitness'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyWitness, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyWitness === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyWitness1Surname']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyWitness'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyWitness, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyWitness === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyOtherWitness']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyLegalCase', 'assistaCompanyWitness'], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyWitness, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyWitness === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyWitness2Gender']: Yup.string()
        .when([
            'assistaFormChoice',
            'assistaCompanyLegalCase',
            'assistaCompanyWitness',
            'assistaCompanyOtherWitness'
        ], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyWitness, assistaCompanyOtherWitness, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyWitness === I18n.t('yesLabel') &&
                assistaCompanyOtherWitness === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyWitness2Firstname']: Yup.string()
        .when([
            'assistaFormChoice',
            'assistaCompanyLegalCase',
            'assistaCompanyWitness',
            'assistaCompanyOtherWitness'
        ], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyWitness, assistaCompanyOtherWitness, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyWitness === I18n.t('yesLabel') &&
                assistaCompanyOtherWitness === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyWitness2Surname']: Yup.string()
        .when([
            'assistaFormChoice',
            'assistaCompanyLegalCase',
            'assistaCompanyWitness',
            'assistaCompanyOtherWitness'
        ], (assistaFormChoice, assistaCompanyLegalCase, assistaCompanyWitness, assistaCompanyOtherWitness, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyLegalCase === I18n.t("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                assistaCompanyWitness === I18n.t('yesLabel') &&
                assistaCompanyOtherWitness === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        })
    //assistaCompanyWitness2Gender
}