import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import AssistaStep1 from "./step/AssistaStep1";
import AssistaStep2 from "./step/AssistaStep2";
import AssistaStep3 from "./step/AssistaStep3";
import AssistaStep4 from "./step/AssistaStep4";
import assistaInitialValues from "../validation/assistaInitialValues";
import assistaValidationSchema from "../validation/assistaValidationSchema";
import axios from "axios";
import Summary from "./step/Summary";
import Spinner from "../../common/ui/Spinner";
import Stepper from "../../common/ui/Stepper";
import {withNamespaces} from 'react-i18next';
import ErrorFocus from "../../common/ErrorFocus";
import Confirmation from "./step/Confirmation";

function Assista({t, i18n}) {
    const postUrl = process.env.REACT_APP_POST_URL ?? '';
    const apiKey = process.env.REACT_APP_API_KEY ?? '';
    const [activeStep, setActiveStep] = useState(0);
    const [confirmation, setConfirmation] = useState(0);
    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(false);

    const currentValidationSchema = assistaValidationSchema[activeStep];
    const steps = [
        t('stepper1Label'),
        t('stepper2Label'),
        t('stepper3Label'),
        t('stepper4Label'),
        t('stepper5Label')
    ];

    const [lastStep] = useState(steps.length-1);
    const [assistaFormChoice, setAssistaFormChoice] = useState('');

    const renderStepComponent = () => {
        switch (activeStep) {
            case 0:
                return <AssistaStep1 translation={t}/>;
            case 1:
                return <AssistaStep2 translation={t}/>;
            case 2:
                return <AssistaStep3 translation={t}/>;
            case 3:
                return <AssistaStep4 translation={t}/>;
            case 4:
                return <Summary translation={t}/>;
            default:
                return <div>Not Found</div>;
        }
    }

    const sendData = async (values) => {
        try {
            await axios.post(postUrl, JSON.stringify(values), {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': apiKey,
                    'Ocp-Apim-Trace': true,
                    'X-Azure-Allowed': 'on'
                }
            }).then(res => {
                setStatus(res.status);
                if (res.status === 200) {
                    setLoading(false);
                    setConfirmation(1);
                } else {
                    setLoading(false);
                    setConfirmation(1);
                }
            }).catch(err => {
                setStatus(500);
                setLoading(false);
                setConfirmation(1);
            })
        } catch (err) {
            setStatus(500);
            setLoading(false);
            setConfirmation(1);
        }
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    }

    const buildDocuments = async (files) => {
        let allFiles = [];
        files.forEach(currentFile => {
            let file = {};
            file['type'] = currentFile.type;
            file['size'] = currentFile.size;
            file['name'] = currentFile.name;

            let reader = new FileReader();
            reader.onload = function () {
                file['value'] = reader.result;
                allFiles.push(file);
            };
            reader.readAsDataURL(currentFile);
        })
        return allFiles;
    }

    const handleSubmit = async (values, actions) => {
        setAssistaFormChoice(values['assistaFormChoice']);
        if (activeStep === lastStep) {
            setLoading(true);
            values['language'] = i18n.language ?? 'de';
            window.parent.postMessage({
                event: "form_submit",
                form_policy: values['assistaFormChoice'],
                form_step: activeStep
            }, "*");
            sendData(values);
        } else {
            if (activeStep === lastStep - 1) {
                values['assistaDocument2'] = await buildDocuments(values['assistaDocument']);
            }
            window.parent.postMessage({
                event: "form_step",
                form_policy: values['assistaFormChoice'],
                form_step: activeStep
            }, "*");
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'instant'});
            if ('parentIFrame' in window) {
                window.parentIFrame.scrollTo(0, 0);
            }
        }, 0);
    }, [activeStep]);

    return (
        <>
            {confirmation === 0 &&
                <>
                    {activeStep !== 0 && activeStep !== lastStep &&
                        <div className={'title-choice'}>
                            <p>{assistaFormChoice}</p>
                        </div>
                    }
                    <Stepper steps={steps} activeStep={activeStep}/>
                    <div className={'app-container'}>
                        <Formik
                            initialValues={assistaInitialValues}
                            validationSchema={currentValidationSchema}
                            onSubmit={handleSubmit}
                        >
                            {formikBag => (
                                <Form className={'form-container'}>
                                    {renderStepComponent()}
                                    <ErrorFocus />
                                    <div className={'item'}>
                                        <div className={'buttons'}>
                                            {activeStep > 0 &&
                                            <button
                                                className={'button' + (loading ? ' buttonDisabled' : '')}
                                                type="button"
                                                onClick={handleBack}
                                                disabled={loading}>
                                                {activeStep !== lastStep ? t('backLabel') : t('ASSISTA_Generic_Form_Step5_Button_Update_Data' +
                                                    '')}
                                            </button>
                                            }
                                            <button
                                                className={'button' + (loading ? ' buttonDisabled' : '')}
                                                type="submit"
                                                disabled={loading}>
                                                {activeStep !== lastStep ? t('nextLabel') : t('sendLabel')}
                                            </button>
                                        </div>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>

                    {loading &&
                        <div className={'container'}>
                            <Spinner />
                        </div>
                    }
                </>
            }

            {confirmation === 1 &&
                <div className={'container'}>
                    <Confirmation status={status} translation={t} i18n={i18n}/>
                </div>
            }
        </>
    )
}

export default withNamespaces()(Assista)