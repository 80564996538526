import {useFormikContext} from "formik";
import React from "react";
import InputComponent from "../../../common/Input";

export default function AssistaSubStep3({translation}) {
    const formikProps = useFormikContext();

    const choices = [
        translation('choice7')
    ];

    function validateRequired(value) {
        let error;
        if (!value) {
            if (formikProps.values['assistaFormChoice'] !== ''
                && choices.includes(formikProps.values['assistaFormChoice'])) {
                error = translation('requiredDefaultMessage');
            }
        }
        return error;
    }

    return (
        <>
            <div className={'item'}>
                <h5>
                    {translation('buildingInformationLabel')}
                </h5>
            </div>

            <div className={'item-street'}>
                <InputComponent
                    data={{
                        name: 'assistaBuildingAddress',
                        label: translation('addressLabel') + '*'
                    }}
                    validate={validateRequired}
                />
            </div>

            <div className={'item-street'}>
                <InputComponent
                    data={{
                        name: 'assistaBuildingAddressNPA',
                        label: translation('npaLabel') + '*'
                    }}
                    validate={validateRequired}
                />
                <InputComponent
                    data={{
                        name: 'assistaBuildingAddressCity',
                        label: translation('cityLabel') + '*',
                        className: "city"
                    }}
                    validate={validateRequired}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaDateDamage',
                        label: translation("ASSISTA_Generic_Form_Field_Damage_Date") + '*',
                        type: "date"
                    }}
                    validate={validateRequired}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaCaseDamage',
                        label: translation("ASSISTA_Generic_Form_Field_Damage_Description") + '*',
                    }}
                    validate={validateRequired}
                />
            </div>

            <div className={'item'}>
                <h5>
                    {translation("ASSISTA_Generic_Form_H3_Damage_Amount")}
                </h5>
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: 'assistaCompensation',
                        label: translation('chfAmountLabel') + '*',
                    }}
                    validate={validateRequired}
                />
            </div>
        </>
    )
}