import RadioComponent from "./Radio";
import {ErrorMessage} from "formik";
import InputComponent from "./Input";
import React from "react";

export default function PersonItem({translation, prefix}) {
    return (
        <>
            <div className={'item'}>
                <label className={'form-label'}>
                    {translation('genderLabel') + '*'}
                </label>
                <RadioComponent
                    data={{
                        id: prefix + 'Gender1',
                        value: 'male',
                        name: prefix + 'Gender',
                        label: translation('genderMaleLabel')
                    }}/>
                <RadioComponent
                    data={{
                        id: prefix + 'Gender2',
                        value: 'female',
                        name: prefix + 'Gender',
                        label: translation('genderFemaleLabel')
                    }}/>
                <RadioComponent
                    data={{
                        id: prefix + 'Gender3',
                        value: 'other',
                        name: prefix + 'Gender',
                        label: translation('genderOtherLabel')
                    }}/>
                <ErrorMessage className="text-danger" name={prefix + 'Gender'} component="div"/>
            </div>

            <div className={'item-names'}>
                <InputComponent
                    data={{
                        name: prefix + 'Firstname',
                        label: translation('firstnameLabel') + '*',
                        inputMode: "text"
                    }}
                />
                <InputComponent
                    data={{
                        name: prefix + 'Surname',
                        label: translation('surnameLabel') + '*',
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: prefix + 'Company',
                        label: translation('counterpartyLabel'),
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: prefix + 'Birthday',
                        label: translation('birthdayLabel') + '*',
                        autoComplete: "bday",
                        type: "date"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: prefix + 'Address',
                        label: translation('addressLabel') + '*',
                        autoComplete: "street-address",
                        inputMode: "text",
                        component: "textarea",
                        rows: "3"
                    }}
                />
            </div>

            <div className={'item-street'}>
                <InputComponent
                    data={{
                        name: prefix + 'AddressNPA',
                        label: translation('npaLabel') + '*',
                        autoComplete: "postal-code",
                        inputMode: "numeric"
                    }}
                />
                <InputComponent
                    data={{
                        name: prefix + 'AddressCity',
                        label: translation('cityLabel') + '*',
                        autoComplete: "address-level2",
                        className: "city",
                        inputMode: "text"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: prefix + 'Phone',
                        label: translation('phoneNumberSimpleLabel') + '*',
                        autoComplete: "tel",
                        inputMode: "tel"
                    }}
                />
            </div>

            <div className={'item'}>
                <InputComponent
                    data={{
                        name: prefix + 'Email',
                        label: translation('emailLabel') + '*',
                        autoComplete: "email",
                        inputMode: "email"
                    }}
                />
            </div>
        </>
    )
}