import React from "react";
import InputComponent from "../../../../../common/Input";
import RadioComponent from "../../../../../common/Radio";
import {ErrorMessage, useFormikContext} from "formik";

export default function BusinessLegalProtection3({translation}) {
    const formikProps = useFormikContext();

    return (
        <>
            <div className={'item'}>
                <h4>
                    {translation("ASSISTA_Generic_Form_Label_Step3")}
                </h4>
            </div>

            <div className={'item'}>
                <label className={'form-label'}>
                    {translation("ASSISTA_Generic_Form_Label_Type_Case") + "*"}
                </label>
                <RadioComponent
                    data={{
                        id: 'assistaCompanyLegalCase1',
                        value: translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal"),
                        name: 'assistaCompanyLegalCase',
                        label: translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal")
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'assistaCompanyLegalCase2',
                        value: translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor"),
                        name: 'assistaCompanyLegalCase',
                        label: translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor")
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'assistaCompanyLegalCase3',
                        value: translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner"),
                        name: 'assistaCompanyLegalCase',
                        label: translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner")
                    }}
                />
                <RadioComponent
                    data={{
                        id: 'assistaCompanyLegalCase4',
                        value: translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver"),
                        name: 'assistaCompanyLegalCase',
                        label: translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver")
                    }}
                />
                <ErrorMessage className="text-danger" name='assistaCompanyLegalCase' component="div"/>
            </div>

            {(formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal")
                    || formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor")
                    || formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner")) &&
                <>
                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation("ASSISTA_Generic_Form_Label_Dispute_Type") + "*"}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute1',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Contract"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Contract")
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute2',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Property"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Property")
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute3',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Debt_Recovery"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Debt_Recovery")
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute4',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Other"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Other")
                            }}
                        />
                        <ErrorMessage className="text-danger" name='assistaCompanyTypeDispute' component="div"/>
                    </div>
                </>
            }

            {formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                <>
                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation("ASSISTA_Generic_Form_Label_Dispute_Type") + "*"}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute1',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Road_Accident"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Road_Accident")
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute2',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Driving_License"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Driving_License")
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute3',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Vehicle"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Type_Vehicle")
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute4',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Own_Insurance"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Dispute_Own_Insurance")
                            }}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaCompanyTypeDispute4',
                                value: translation("ASSISTA_Company_Form_Step3_Field_Reason_Other"),
                                name: 'assistaCompanyTypeDispute',
                                label: translation("ASSISTA_Company_Form_Step3_Field_Reason_Other")
                            }}
                        />
                        <ErrorMessage className="text-danger" name='assistaCompanyTypeDispute' component="div"/>
                    </div>
                </>
            }

            {formikProps.values['assistaCompanyLegalCase'] !== '' &&
                <>
                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompanyDateDamage',
                                label: translation("ASSISTA_Generic_Form_Field_Damage_Date") + '*',
                                type: "date"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompanyExactPlace',
                                label: translation('exactPlaceLabel') + '*',
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompanyLand',
                                label: translation('landLabel') + '*',
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Damage_Cause")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompanyDescriptionCaseDamage',
                                label: translation("ASSISTA_Generic_Form_Field_Damage_Description") + '*',
                                component: "textarea"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation("ASSISTA_Generic_Form_H3_Damage_Amount")}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompanyCompensation',
                                label: translation('chfAmountLabel') + '*'
                            }}
                        />
                    </div>

                    {(formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Business_Legal")
                            || formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Lessor")
                            || formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Family_Owner")) &&
                        <>
                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_Step3_H3_Counter_Party")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyHolderName',
                                        label: translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable"),
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Generic_Form_Label_Gender")}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCounterPartyGender1',
                                        value: 'male',
                                        name: 'assistaCompanyCounterPartyGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                                    }}/>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCounterPartyGender2',
                                        value: 'female',
                                        name: 'assistaCompanyCounterPartyGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                                    }}/>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCounterPartyGender3',
                                        value: 'other',
                                        name: 'assistaCompanyCounterPartyGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                                    }}/>
                                <ErrorMessage className="text-danger" name='assistaCompanyCounterPartyGender' component="div"/>
                            </div>

                            <div className={'item-names'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyFirstname',
                                        label: translation("ASSISTA_Generic_Form_Field_Firstname"),
                                        inputMode: "text"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartySurname',
                                        label: translation("ASSISTA_Generic_Form_Field_Surename"),
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item-street'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyAddress',
                                        label: translation("ASSISTA_Generic_Form_Field_Street_Number") + "*",
                                        inputMode: "text",
                                        component: "textarea",
                                        rows: "3"
                                    }}
                                />
                            </div>

                            <div className={'item-street'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyAddressNPA',
                                        label: translation("ASSISTA_Generic_Form_Field_NPA") + "*",
                                        inputMode: "numeric"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyAddressCity',
                                        label: translation("ASSISTA_Generic_Form_Field_Location") + "*",
                                        className: "city",
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyAddressLand',
                                        label: translation("ASSISTA_Generic_Form_Field_Country") + "*",
                                        inputMode: "text"
                                    }}
                                />
                            </div>
                        </>
                    }

                    {formikProps.values['assistaCompanyLegalCase'] === translation("ASSISTA_Company_Form_Step3_Field_Type_Case_Traffic_Driver") &&
                        <>
                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Accident_Report") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyAccidentReportMade1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyAccidentReportMade',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyAccidentReportMade2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyAccidentReportMade',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyAccidentReportMade' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyAccidentReportMade'] === translation('yesLabel') &&
                                <div className={'item-message'}>
                                    <p>{translation("ASSISTA_Generic_Text_Upload_Copy")}</p>
                                </div>
                            }

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Acknowledgment_Debt") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanySignedAcknowledgment1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanySignedAcknowledgment',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanySignedAcknowledgment2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanySignedAcknowledgment',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanySignedAcknowledgment' component="div" />
                            </div>

                            {formikProps.values['assistaCompanySignedAcknowledgment'] === translation('yesLabel') &&
                                <div className={'item-message'}>
                                    <p>{translation("ASSISTA_Generic_Text_Upload_Copy_Next_Step_If_Available")}</p>
                                </div>
                            }

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Acknowledgment_Debt_Opposing_Party") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanySignedAcknowledgmentOpposingParty1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanySignedAcknowledgmentOpposingParty',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanySignedAcknowledgmentOpposingParty2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanySignedAcknowledgmentOpposingParty',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanySignedAcknowledgmentOpposingParty' component="div" />
                            </div>

                            {formikProps.values['assistaCompanySignedAcknowledgmentOpposingParty'] === translation('yesLabel') &&
                                <div className={'item-message'}>
                                    <p>{translation("ASSISTA_Generic_Text_Upload_Copy_Next_Step_If_Available")}</p>
                                </div>
                            }

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Police_There") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyPoliceHere1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyPoliceHere',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyPoliceHere2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyPoliceHere',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyPoliceHere' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyPoliceHere'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Company_Form_Step3_Label_Police_Report") + "*"}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyPoliceReportMade1',
                                                value: translation('yesLabel'),
                                                name: 'assistaCompanyPoliceReportMade',
                                                label: translation('yesLabel')
                                            }}
                                        />
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyPoliceReportMade2',
                                                value: translation('noLabel'),
                                                name: 'assistaCompanyPoliceReportMade',
                                                label: translation('noLabel')
                                            }}
                                        />
                                        <ErrorMessage className="text-danger" name='assistaCompanyPoliceReportMade' component="div" />
                                    </div>

                                    {formikProps.values['assistaCompanyPoliceReportMade'] === translation('yesLabel') &&
                                        <>
                                            <div className={'item-message'}>
                                                <p>{translation("ASSISTA_Generic_Text_Upload_Copy_Next_Step_If_Available")}</p>
                                            </div>

                                            <div className={'item'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyPoliceStation',
                                                        label: translation("ASSISTA_Company_Form_Step3_Label_Police_Station"),
                                                        inputMode: "text"
                                                    }}
                                                />
                                            </div>

                                            <div className={'item'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyAccusedOf',
                                                        label: translation("ASSISTA_Company_Form_Step3_Label_Accused_Of"),
                                                        inputMode: "text"
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_License_Withdrawn") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyLicenseTakenAway1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyLicenseTakenAway',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyLicenseTakenAway2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyLicenseTakenAway',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyLicenseTakenAway' component="div" />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Blood_Test") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyBloodTest1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyBloodTest',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyBloodTest2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyBloodTest',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyBloodTest' component="div" />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Criminal_Measure") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCriminalMeasure1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyCriminalMeasure',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCriminalMeasure2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyCriminalMeasure',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyCriminalMeasure' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyCriminalMeasure'] === translation('yesLabel') &&
                                <>
                                    <div className={'item-message'}>
                                        <p>{translation("ASSISTA_Generic_Text_Upload_Copy_Next_Step_If_Available")}</p>
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCriminalMeasureDate',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_Criminal_Measure_Date_Receipt") + "*",
                                                type: "date"
                                            }}
                                        />
                                    </div>
                                </>
                            }

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_People_Injured") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyPeopleInjured1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyPeopleInjured',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyPeopleInjured2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyPeopleInjured',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyPeopleInjured' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyPeopleInjured'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyPeopleInjuredInformations',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_People_Injured_Information") + "*",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Company_Form_Step3_Label_Declaration_Made") + "*"}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyDeclarationAccidentInsurance1',
                                                value: translation('yesLabel'),
                                                name: 'assistaCompanyDeclarationAccidentInsurance',
                                                label: translation('yesLabel')
                                            }}
                                        />
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyDeclarationAccidentInsurance2',
                                                value: translation('noLabel'),
                                                name: 'assistaCompanyDeclarationAccidentInsurance',
                                                label: translation('noLabel')
                                            }}
                                        />
                                        <ErrorMessage className="text-danger" name='assistaCompanyDeclarationAccidentInsurance' component="div" />
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Company_Form_Step3_Label_Other_People_Injured") + "*"}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyOtherPeopleInjured1',
                                                value: translation('yesLabel'),
                                                name: 'assistaCompanyOtherPeopleInjured',
                                                label: translation('yesLabel')
                                            }}
                                        />
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyOtherPeopleInjured2',
                                                value: translation('noLabel'),
                                                name: 'assistaCompanyOtherPeopleInjured',
                                                label: translation('noLabel')
                                            }}
                                        />
                                        <ErrorMessage className="text-danger" name='assistaCompanyOtherPeopleInjured' component="div" />
                                    </div>

                                    {formikProps.values['assistaCompanyOtherPeopleInjured'] === translation('yesLabel') &&
                                        <>
                                            <div className={'item'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyOtherPeopleInjuredInformations',
                                                        label: translation("ASSISTA_Company_Form_Step3_Field_People_Injured_Information") + "*",
                                                        inputMode: "text"
                                                    }}
                                                />
                                            </div>

                                            <div className={'item'}>
                                                <label className={'form-label'}>
                                                    {translation("ASSISTA_Company_Form_Step3_Label_Declaration_Made") + "*"}
                                                </label>
                                                <RadioComponent
                                                    data={{
                                                        id: 'assistaCompanyOtherDeclarationAccidentInsurance1',
                                                        value: translation('yesLabel'),
                                                        name: 'assistaCompanyOtherDeclarationAccidentInsurance',
                                                        label: translation('yesLabel')
                                                    }}
                                                />
                                                <RadioComponent
                                                    data={{
                                                        id: 'assistaCompanyOtherDeclarationAccidentInsurance2',
                                                        value: translation('noLabel'),
                                                        name: 'assistaCompanyOtherDeclarationAccidentInsurance',
                                                        label: translation('noLabel')
                                                    }}
                                                />
                                                <ErrorMessage className="text-danger" name='assistaCompanyOtherDeclarationAccidentInsurance' component="div" />
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_Step3_H3_Vehicle_Informations")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyMark',
                                        label: translation("ASSISTA_Generic_Form_Field_Brand") + '*'
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyModel',
                                        label: translation("ASSISTA_Generic_Form_Field_Plate") + '*'
                                    }}
                                />
                            </div>

                            <div className={'item-names'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyFirstDatePlacingMarket',
                                        label: translation('firstDatePlacingMarketLabel') + '*',
                                        type: "date"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyKilometerStatus',
                                        label: translation('kilometerStatusLabel') + '*'
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Items_Damaged") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyItemsDamaged1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyItemsDamaged',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyItemsDamaged2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyItemsDamaged',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyItemsDamaged' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyItemsDamaged'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyListItemsDamaged',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_List_Items_Damaged") + '*',
                                                inputMode: "text",
                                                component: "textarea",
                                                rows: "3"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyOwnerItemsAmountDamagedCompany',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_Amount_Items_Damaged") + '*',
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <h5>
                                            {translation("ASSISTA_Company_Form_Step3_Label_Owner_Items")}
                                        </h5>
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyOwnerItemsDamagedCompany',
                                                label: translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Generic_Form_Label_Gender")}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyOwnerItemsDamagedGender1',
                                                value: 'male',
                                                name: 'assistaCompanyOwnerItemsDamagedGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                                            }}/>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyOwnerItemsDamagedGender2',
                                                value: 'female',
                                                name: 'assistaCompanyOwnerItemsDamagedGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                                            }}/>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyOwnerItemsDamagedGender3',
                                                value: 'other',
                                                name: 'assistaCompanyOwnerItemsDamagedGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                                            }}/>
                                        <ErrorMessage className="text-danger" name='assistaCompanyOwnerItemsDamagedGender' component="div"/>
                                    </div>

                                    <div className={'item-names'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyOwnerItemsDamagedFirstname',
                                                label: translation("ASSISTA_Generic_Form_Field_Firstname"),
                                                inputMode: "text"
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyOwnerItemsDamagedSurname',
                                                label: translation("ASSISTA_Generic_Form_Field_Surename"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item-street'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyOwnerItemsDamagedAddress',
                                                label: translation("ASSISTA_Generic_Form_Field_Street_Number") + "*",
                                                inputMode: "text",
                                                component: "textarea",
                                                rows: "3"
                                            }}
                                        />
                                    </div>

                                    <div className={'item-street'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyOwnerItemsDamagedAddressNPA',
                                                label: translation("ASSISTA_Generic_Form_Field_NPA") + "*",
                                                inputMode: "numeric"
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyOwnerItemsDamagedAddressCity',
                                                label: translation("ASSISTA_Generic_Form_Field_Location") + "*",
                                                className: "city",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyOwnerItemsDamagedAddressLand',
                                                label: translation("ASSISTA_Generic_Form_Field_Country") + '*',
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>
                                </>
                            }

                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_H3_Expertise")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Assessed_Expert") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleAssessed1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyVehicleAssessed',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleAssessed2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyVehicleAssessed',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyVehicleAssessed' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyVehicleAssessed'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleExpertName',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_Assessed_Expert_Name") + '*',
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleExpertMandated',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_Assessed_Expert_Mandated_By"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>
                                </>
                            }

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Vehicle_Repared") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleRepaired1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyVehicleRepaired',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleRepaired2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyVehicleRepaired',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyVehicleRepaired' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyVehicleRepaired'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleRepairedBy',
                                                label: translation("ASSISTA_Company_Form_Step3_Label_Vehicle_Repared_By") + "*",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>
                                </>
                            }

                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Vehicle_Insurance")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyNameInsuranceVehicle',
                                        label: translation("ASSISTA_Company_Form_Step3_Field_Vehicle_Insurance_Name") + "*",
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyPoliceNumberVehicle',
                                        label: translation("ASSISTA_Generic_Form_Field_Police_Number") + "*",
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Damage_Claimed") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleDeclaredIncident1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyVehicleDeclaredIncident',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleDeclaredIncident2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyVehicleDeclaredIncident',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyVehicleDeclaredIncident' component="div" />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Assurance_Casco")}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleCasco1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyVehicleCasco',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleCasco2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyVehicleCasco',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyVehicleCasco' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyVehicleCasco'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCascoPoliceNumber',
                                                label: translation("ASSISTA_Generic_Form_Field_Police_Number") + "*",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyCascoFullOrPartial1',
                                                value: translation("ASSISTA_Company_Form_Step3_Field_Full_Casco"),
                                                name: 'assistaCompanyCascoFullOrPartial',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_Full_Casco")
                                            }}
                                        />
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyCascoFullOrPartial2',
                                                value: translation("ASSISTA_Company_Form_Step3_Field_Partial_Casco"),
                                                name: 'assistaCompanyCascoFullOrPartial',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_Partial_Casco")
                                            }}
                                        />
                                        <ErrorMessage className="text-danger" name='assistaCompanyCascoFullOrPartial' component="div" />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCascoFranchise',
                                                label: translation("ASSISTA_Company_Form_Step3_Field_Deductible_Casco") + "*",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Company_Form_Step3_Label_Casco_Claimed") + "*"}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyVehicleCascoDeclared1',
                                                value: translation('yesLabel'),
                                                name: 'assistaCompanyVehicleCascoDeclared',
                                                label: translation('yesLabel')
                                            }}
                                        />
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyVehicleCascoDeclared2',
                                                value: translation('noLabel'),
                                                name: 'assistaCompanyVehicleCascoDeclared',
                                                label: translation('noLabel')
                                            }}
                                        />
                                        <ErrorMessage className="text-danger" name='assistaCompanyVehicleCascoDeclared' component="div" />
                                    </div>
                                </>
                            }
                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_Field_Vehicle_Owner")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Company_Own_Vehicle") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleCompanyOwner1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyVehicleCompanyOwner',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleCompanyOwner2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyVehicleCompanyOwner',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyVehicleCompanyOwner' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyVehicleCompanyOwner'] === translation('noLabel') &&
                                <>
                                    <div className={'item'}>
                                        <h6>
                                            {translation("ASSISTA_Generic_Form_Field_Vehicle_Owner")}
                                        </h6>
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleOwnerCompany',
                                                label: translation("ASSISTA_Generic_Form_Field_Company_If_Applicable"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Generic_Form_Label_Gender")}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyVehicleOwnerGender1',
                                                value: 'male',
                                                name: 'assistaCompanyVehicleOwnerGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                                            }}/>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyVehicleOwnerGender2',
                                                value: 'female',
                                                name: 'assistaCompanyVehicleOwnerGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                                            }}/>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyVehicleOwnerGender3',
                                                value: 'other',
                                                name: 'assistaCompanyVehicleOwnerGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                                            }}/>
                                        <ErrorMessage className="text-danger" name='assistaCompanyVehicleOwnerGender' component="div"/>
                                    </div>

                                    <div className={'item-names'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleOwnerFirstname',
                                                label: translation("ASSISTA_Generic_Form_Field_Firstname"),
                                                inputMode: "text"
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleOwnerSurname',
                                                label: translation("ASSISTA_Generic_Form_Field_Surename"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item-street'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleOwnerAddress',
                                                label: translation("ASSISTA_Generic_Form_Field_Street_Number") + "*",
                                                inputMode: "text",
                                                component: "textarea",
                                                rows: "3"
                                            }}
                                        />
                                    </div>

                                    <div className={'item-street'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleOwnerAddressNPA',
                                                label: translation("ASSISTA_Generic_Form_Field_NPA") + "*",
                                                inputMode: "numeric"
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleOwnerAddressCity',
                                                label: translation("ASSISTA_Generic_Form_Field_Location") + "*",
                                                className: "city",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyVehicleOwnerAddressLand',
                                                label: translation("ASSISTA_Generic_Form_Field_Country") + '*',
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>
                                </>
                            }
                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_H3_Driver")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Generic_Form_Label_Gender") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleDriverGender1',
                                        value: 'male',
                                        name: 'assistaCompanyVehicleDriverGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                                    }}/>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleDriverGender2',
                                        value: 'female',
                                        name: 'assistaCompanyVehicleDriverGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                                    }}/>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyVehicleDriverGender3',
                                        value: 'other',
                                        name: 'assistaCompanyVehicleDriverGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                                    }}/>
                                <ErrorMessage className="text-danger" name='assistaCompanyVehicleDriverGender' component="div"/>
                            </div>

                            <div className={'item-names'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyVehicleDriverFirstname',
                                        label: translation("ASSISTA_Generic_Form_Field_Firstname") + "*",
                                        inputMode: "text"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyVehicleDriverSurname',
                                        label: translation("ASSISTA_Generic_Form_Field_Surename") + "*",
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item-street'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyVehicleDriverAddress',
                                        label: translation("ASSISTA_Generic_Form_Field_Street_Number") + "*",
                                        inputMode: "text",
                                        component: "textarea",
                                        rows: "3"
                                    }}
                                />
                            </div>

                            <div className={'item-street'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyVehicleDriverAddressNPA',
                                        label: translation("ASSISTA_Generic_Form_Field_NPA") + "*",
                                        inputMode: "numeric"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyVehicleDriverAddressCity',
                                        label: translation("ASSISTA_Generic_Form_Field_Location") + "*",
                                        className: "city",
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyVehicleDriverAddressLand',
                                        label: translation("ASSISTA_Generic_Form_Field_Country") + '*',
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_Text_Driving_License")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyVehicleDriverLicenseCategory',
                                        label: translation("ASSISTA_Generic_Form_Field_Driving_License_Type") + '*',
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyVehicleDriverLicenseDate',
                                        label: translation("ASSISTA_Generic_Form_Field_Driving_License_Date") + '*',
                                        type: "date"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_Step3_H3_Counter_Party")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyHolderName',
                                        label: translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable"),
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Generic_Form_Label_Gender")}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCounterPartyGender1',
                                        value: 'male',
                                        name: 'assistaCompanyCounterPartyGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                                    }}/>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCounterPartyGender2',
                                        value: 'female',
                                        name: 'assistaCompanyCounterPartyGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                                    }}/>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCounterPartyGender3',
                                        value: 'other',
                                        name: 'assistaCompanyCounterPartyGender',
                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                                    }}/>
                                <ErrorMessage className="text-danger" name='assistaCompanyCounterPartyGender' component="div"/>
                            </div>

                            <div className={'item-names'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyFirstname',
                                        label: translation("ASSISTA_Generic_Form_Field_Firstname"),
                                        inputMode: "text"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartySurname',
                                        label: translation("ASSISTA_Generic_Form_Field_Surename"),
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item-street'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyAddress',
                                        label: translation("ASSISTA_Generic_Form_Field_Street_Number") + "*",
                                        inputMode: "text",
                                        component: "textarea",
                                        rows: "3"
                                    }}
                                />
                            </div>

                            <div className={'item-street'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyAddressNPA',
                                        label: translation("ASSISTA_Generic_Form_Field_NPA") + "*",
                                        inputMode: "numeric"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyAddressCity',
                                        label: translation("ASSISTA_Generic_Form_Field_Location") + "*",
                                        className: "city",
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyAddressLand',
                                        label: translation("ASSISTA_Generic_Form_Field_Country") + "*",
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_H3_Data_Opposing_Party_If_Relevant")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyMark',
                                        label: translation("ASSISTA_Generic_Form_Field_Brand") + '*'
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyModel',
                                        label: translation("ASSISTA_Generic_Form_Field_Plate") + '*'
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCompanyCounterPartyNameInsuranceVehicle',
                                        label: translation("ASSISTA_Generic_Form_Step3_Field_Vehicle_Insurance"),
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Opposing_Party_Owner") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCounterPartyOwner1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyCounterPartyOwner',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyCounterPartyOwner2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyCounterPartyOwner',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyCounterPartyOwner' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyCounterPartyOwner'] === translation('noLabel') &&
                                <>
                                    <div className={'item'}>
                                        <h5>
                                            {translation("ASSISTA_Generic_Form_Field_Vehicle_Owner")}
                                        </h5>
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCounterPartyOwnerCompanyName',
                                                label: translation("ASSISTA_Generic_Form_Field_Company_Name_If_Applicable"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Generic_Form_Label_Gender")}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyCounterPartyOwnerGender1',
                                                value: 'male',
                                                name: 'assistaCompanyCounterPartyOwnerGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                                            }}/>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyCounterPartyOwnerGender2',
                                                value: 'female',
                                                name: 'assistaCompanyCounterPartyOwnerGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                                            }}/>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyCounterPartyOwnerGender3',
                                                value: 'other',
                                                name: 'assistaCompanyCounterPartyOwnerGender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                                            }}/>
                                        <ErrorMessage className="text-danger" name='assistaCompanyCounterPartyOwnerGender' component="div"/>
                                    </div>

                                    <div className={'item-names'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCounterPartyOwnerFirstname',
                                                label: translation("ASSISTA_Generic_Form_Field_Firstname"),
                                                inputMode: "text"
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCounterPartyOwnerSurname',
                                                label: translation("ASSISTA_Generic_Form_Field_Surename"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item-street'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCounterPartyOwnerAddress',
                                                label: translation("ASSISTA_Generic_Form_Field_Street_Number"),
                                                inputMode: "text",
                                                component: "textarea",
                                                rows: "3"
                                            }}
                                        />
                                    </div>

                                    <div className={'item-street'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCounterPartyOwnerAddressNPA',
                                                label: translation("ASSISTA_Generic_Form_Field_NPA"),
                                                inputMode: "numeric"
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCounterPartyOwnerAddressCity',
                                                label: translation("ASSISTA_Generic_Form_Field_Location"),
                                                className: "city",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyCounterPartyOwnerAddressLand',
                                                label: translation("ASSISTA_Generic_Form_Field_Country"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>
                                </>
                            }
                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_H3_Witnesses")}
                                </h5>
                            </div>

                            <div className={'item'}>
                                <label className={'form-label'}>
                                    {translation("ASSISTA_Company_Form_Step3_Label_Witnesses") + "*"}
                                </label>
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyWitness1',
                                        value: translation('yesLabel'),
                                        name: 'assistaCompanyWitness',
                                        label: translation('yesLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaCompanyWitness2',
                                        value: translation('noLabel'),
                                        name: 'assistaCompanyWitness',
                                        label: translation('noLabel')
                                    }}
                                />
                                <ErrorMessage className="text-danger" name='assistaCompanyWitness' component="div" />
                            </div>

                            {formikProps.values['assistaCompanyWitness'] === translation('yesLabel') &&
                                <>
                                    <div className={'item'}>
                                        <h5>
                                            {translation("ASSISTA_Company_Form_Step3_H3_Witness1_Informations")}
                                        </h5>
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Generic_Form_Label_Gender") + "*"}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyWitness1Gender1',
                                                value: 'male',
                                                name: 'assistaCompanyWitness1Gender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                                            }}/>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyWitness1Gender2',
                                                value: 'female',
                                                name: 'assistaCompanyWitness1Gender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                                            }}/>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyWitness1Gender3',
                                                value: 'other',
                                                name: 'assistaCompanyWitness1Gender',
                                                label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                                            }}/>
                                        <ErrorMessage className="text-danger" name='assistaCompanyWitness1Gender' component="div"/>
                                    </div>

                                    <div className={'item-names'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyWitness1Firstname',
                                                label: translation("ASSISTA_Generic_Form_Field_Firstname") + "*",
                                                inputMode: "text"
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyWitness1Surname',
                                                label: translation("ASSISTA_Generic_Form_Field_Surename") + "*",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item-street'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyWitness1Address',
                                                label: translation("ASSISTA_Generic_Form_Field_Street_Number"),
                                                inputMode: "text",
                                                component: "textarea",
                                                rows: "3"
                                            }}
                                        />
                                    </div>

                                    <div className={'item-street'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyWitness1AddressNPA',
                                                label: translation("ASSISTA_Generic_Form_Field_NPA"),
                                                inputMode: "numeric"
                                            }}
                                        />
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyWitness1AddressCity',
                                                label: translation("ASSISTA_Generic_Form_Field_Location"),
                                                className: "city",
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyWitness1AddressLand',
                                                label: translation("ASSISTA_Generic_Form_Field_Country"),
                                                inputMode: "text"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyWitness1Phone',
                                                label: translation("ASSISTA_Generic_Form_Field_Phone"),
                                                inputMode: "tel"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <InputComponent
                                            data={{
                                                name: 'assistaCompanyWitness1Email',
                                                label: translation("ASSISTA_Generic_Form_Field_Email"),
                                                inputMode: "email"
                                            }}
                                        />
                                    </div>

                                    <div className={'item'}>
                                        <label className={'form-label'}>
                                            {translation("ASSISTA_Company_Form_Step3_Label_Others_Witnesses") + "*"}
                                        </label>
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyOtherWitness1',
                                                value: translation('yesLabel'),
                                                name: 'assistaCompanyOtherWitness',
                                                label: translation('yesLabel')
                                            }}
                                        />
                                        <RadioComponent
                                            data={{
                                                id: 'assistaCompanyOtherWitness2',
                                                value: translation('noLabel'),
                                                name: 'assistaCompanyOtherWitness',
                                                label: translation('noLabel')
                                            }}
                                        />
                                        <ErrorMessage className="text-danger" name='assistaCompanyOtherWitness' component="div" />
                                    </div>

                                    {formikProps.values['assistaCompanyOtherWitness'] === translation('yesLabel') &&
                                        <>
                                            <div className={'item'}>
                                                <h5>
                                                    {translation("ASSISTA_Company_Form_Step3_H3_Witness2_Informations")}
                                                </h5>
                                            </div>

                                            <div className={'item'}>
                                                <label className={'form-label'}>
                                                    {translation("ASSISTA_Generic_Form_Label_Gender") + "*"}
                                                </label>
                                                <RadioComponent
                                                    data={{
                                                        id: 'assistaCompanyWitness2Gender1',
                                                        value: 'male',
                                                        name: 'assistaCompanyWitness2Gender',
                                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Male")
                                                    }}/>
                                                <RadioComponent
                                                    data={{
                                                        id: 'assistaCompanyWitness2Gender2',
                                                        value: 'female',
                                                        name: 'assistaCompanyWitness2Gender',
                                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Female")
                                                    }}/>
                                                <RadioComponent
                                                    data={{
                                                        id: 'assistaCompanyWitness2Gender3',
                                                        value: 'other',
                                                        name: 'assistaCompanyWitness2Gender',
                                                        label: translation("ASSISTA_Generic_Form_Field_Gender_Other")
                                                    }}/>
                                                <ErrorMessage className="text-danger" name='assistaCompanyWitness2Gender' component="div"/>
                                            </div>

                                            <div className={'item-names'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyWitness2Firstname',
                                                        label: translation("ASSISTA_Generic_Form_Field_Firstname") + "*",
                                                        inputMode: "text"
                                                    }}
                                                />
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyWitness2Surname',
                                                        label: translation("ASSISTA_Generic_Form_Field_Surename") + "*",
                                                        inputMode: "text"
                                                    }}
                                                />
                                            </div>

                                            <div className={'item-street'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyWitness2Address',
                                                        label: translation("ASSISTA_Generic_Form_Field_Street_Number"),
                                                        inputMode: "text",
                                                        component: "textarea",
                                                        rows: "3"
                                                    }}
                                                />
                                            </div>

                                            <div className={'item-street'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyWitness2AddressNPA',
                                                        label: translation("ASSISTA_Generic_Form_Field_NPA"),
                                                        inputMode: "numeric"
                                                    }}
                                                />
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyWitness2AddressCity',
                                                        label: translation("ASSISTA_Generic_Form_Field_Location"),
                                                        className: "city",
                                                        inputMode: "text"
                                                    }}
                                                />
                                            </div>

                                            <div className={'item'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyWitness2AddressLand',
                                                        label: translation("ASSISTA_Generic_Form_Field_Country"),
                                                        inputMode: "text"
                                                    }}
                                                />
                                            </div>

                                            <div className={'item'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyWitness2Phone',
                                                        label: translation("ASSISTA_Generic_Form_Field_Phone"),
                                                        inputMode: "tel"
                                                    }}
                                                />
                                            </div>

                                            <div className={'item'}>
                                                <InputComponent
                                                    data={{
                                                        name: 'assistaCompanyWitness2Email',
                                                        label: translation("ASSISTA_Generic_Form_Field_Email"),
                                                        inputMode: "email"
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}