import * as Yup from 'yup';
import 'yup-phone';

import I18n from '../../../i18n';
import {validationSchemaBusinessLegalProtection2} from "./businessLegalProtection/businessLegalProtectionSchema2";
import {validationSchemaBusinessLegalProtection3} from "./businessLegalProtection/businessLegalProtectionSchema3";
import {validationSchemaBusinessLegalProtection4} from "./businessLegalProtection/businessLegalProtectionSchema4";

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const ibantools = require('ibantools');

const choice1 = [
    I18n.t('choice1')
];

const choice3 = [
    I18n.t('choice3')
];

const schemaValidationArray = [
    Yup.object().shape({
        ['assistaFormChoice']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['assistaReasonDamage']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (I18n.t('choice8') !== assistaFormChoice) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
    }),
    Yup.object().shape({
        ['assistaInsurer']: Yup.string(),
        ['assistaPoliceNumber']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['assistaCompanyName']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (I18n.t('choice8') === assistaFormChoice) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['assistaGender']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['assistaFirstname']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['assistaSurname']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['assistaBirthday']: Yup.date(),
        ['assistaAddress']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (I18n.t('choice8') !== assistaFormChoice) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['assistaAddressNPA']: Yup.string()
            .matches(/^[0-9]*$/, I18n.t('validateDefaultMessage'))
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (I18n.t('choice8') !== assistaFormChoice) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['assistaAddressCity']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (I18n.t('choice8') !== assistaFormChoice) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['assistaPhone']: Yup.string()
            .required(I18n.t('requiredDefaultMessage'))
            .test(
                'phone',
                I18n.t('validateDefaultMessage'),
                async (value) => {
                    try {
                        if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'CH'), 'CH')
                            || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'FR'), 'FR')
                            || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'DE'), 'DE')
                            || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'IT'), 'IT')
                        ) {
                            return true;
                        }
                    } catch (error) {
                        return false;
                    }
                }
            ),
        ['assistaPhoneBis']: Yup.mixed()
            .test(
                'phoneBis',
                I18n.t('validateDefaultMessage'),
                async (value) => {
                    if (value) {
                        try {
                            if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'CH'), 'CH')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'FR'), 'FR')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'DE'), 'DE')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'IT'), 'IT')
                            ) {
                                return true;
                            }
                        } catch (error) {
                            return false;
                        }
                    } else {
                        return true;
                    }
                }
            ),
        ['assistaLand']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (I18n.t('choice8') !== assistaFormChoice) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['assistaEmail']: Yup.string()
            .required(I18n.t('requiredDefaultMessage'))
            .email(I18n.t('validateDefaultMessage')),
        ['assistaHolderGender']: Yup.string(),
        ['assistaHolderFirstname']: Yup.string(),
        ['assistaHolderSurname']: Yup.string(),
        ['assistaHolderCompany']: Yup.string(),
        ['assistaHolderBirthday']: Yup.date(),
        ['assistaHolderAddress']: Yup.string(),
        ['assistaHolderAddressNPA']: Yup.string()
            .matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')),
        ['assistaHolderAddressCity']: Yup.string(),
        ['assistaHolderPhone']: Yup.mixed()
            .test(
                'phoneBis',
                I18n.t('validateDefaultMessage'),
                async (value) => {
                    if (value) {
                        try {
                            if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'CH'), 'CH')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'FR'), 'FR')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'DE'), 'DE')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'IT'), 'IT')
                            ) {
                                return true;
                            }
                        } catch (error) {
                            return false;
                        }
                    } else {
                        return true;
                    }
                }
            ),
        ['assistaHolderEmail']: Yup.string()
            .email(I18n.t('validateDefaultMessage')),
        ['assistaHolderConnection']: Yup.string(),
        ['assistaOtherInsuredPerson']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (choice1.includes(assistaFormChoice)) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['assistaOtherInsuredPersonGender']: Yup.string()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaOtherInsuredPersonFirstname']: Yup.string()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaOtherInsuredPersonSurname']: Yup.string()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaOtherInsuredPersonCompany']: Yup.string(),
        ['assistaOtherInsuredPersonBirthday']: Yup.date()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.date().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaOtherInsuredPersonAddress']: Yup.string()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaOtherInsuredPersonAddressNPA']: Yup.string()
            .matches(/^[0-9]*$/, I18n.t('validateDefaultMessage'))
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaOtherInsuredPersonAddressCity']: Yup.string()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaOtherInsuredPersonPhone']: Yup.string()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage')).test(
                    'phone',
                    I18n.t('validateDefaultMessage'),
                    async (value) => {
                        try {
                            if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'CH'), 'CH')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'FR'), 'FR')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'DE'), 'DE')
                                || phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'IT'), 'IT')
                            ) {
                                return true;
                            }
                        } catch (error) {
                            return false;
                        }
                    }
                )
            }),
        ['assistaOtherInsuredPersonEmail']: Yup.string()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage')).email(I18n.t('validateDefaultMessage'))
            }),
        ['assistaBankAccountPaymentInstitution']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (choice1.includes(assistaFormChoice) || I18n.t('choice8') === assistaFormChoice) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['assistaBankAccountPaymentIban']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (choice1.includes(assistaFormChoice) || I18n.t('choice8') === assistaFormChoice) {
                    return schema.required(I18n.t('requiredDefaultMessage'));
                }
                return schema;
            })
            .test(
                'ibanFormat',
                I18n.t('validateDefaultMessage'),
                value => {
                    if (value) {
                        return ibantools.isValidIBAN(ibantools.electronicFormatIBAN(value));
                    } else {
                        return true;
                    }
                }
            ),
        ['assistaBankAccountPaymentHolder']: Yup.string()
            .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
                if (choice1.includes(assistaFormChoice) || I18n.t('choice8') === assistaFormChoice) {
                    return Yup.string().required(I18n.t('requiredDefaultMessage'));
                }
            }),
        ['assistaOtherInsuredPersonConnection']: Yup.string()
            .when('assistaOtherInsuredPerson', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ...validationSchemaBusinessLegalProtection2
    }),
    Yup.object().shape({
        ['assistaMark']: Yup.string(),
        ['assistaModel']: Yup.string(),
        ['assistaCaseDamage']: Yup.string()
            .when(['assistaFormChoice', 'assistaReasonDamage'], (assistaFormChoice, assistaReasonDamage, schema) => {
                if (!choice3.includes(assistaFormChoice) && assistaFormChoice !== I18n.t('choice8') ) {
                    if (choice1.includes(assistaFormChoice)
                        && (assistaReasonDamage === I18n.t('incidentLabel') || assistaReasonDamage === I18n.t('otherDisputeLabel'))) {
                        return Yup.string().required(I18n.t('requiredDefaultMessage'));
                    } else {
                        return Yup.string().required(I18n.t('requiredDefaultMessage'));
                    }
                }
            }),
        ['assistaDateDamage']: Yup.date(),
        ['assistaBuildingAddress']: Yup.string(),
        ['assistaBuildingAddressNPA']: Yup.string()
            .matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')),
        ['assistaBuildingAddressCity']: Yup.string(),
        ['assistaExactPlace']: Yup.string(),
        ['assistaExactPlaceLand']: Yup.string(),
        ['assistaExactPlaceDescription']: Yup.string(),
        ['assistaCompensation']: Yup.string(),
        ['assistaCounterpartyAddress']: Yup.string(),
        ['assistaCounterpartyAddressNPA']: Yup.string()
            .matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')),
        ['assistaCounterpartyAddressCity']: Yup.string(),
        ['assistaCounterpartyAddressLand']: Yup.string(),
        ['assistaCounterpartyName']: Yup.string(),
        ['assistaCounterpartyFirstname']: Yup.string(),
        ['assistaCounterpartyCompany']: Yup.string(),
        ['assistaAccidentReportMade']: Yup.string(),
        ['assistaPoliceThere']: Yup.string(),
        ['assistaInjuredPeople']: Yup.string(),
        ['assistaInjuredPeopleInformation']: Yup.string(),
        ['assistaAccidentInsuranceReported']: Yup.string(),
        ['assistaFirstDatePlacingMarket']: Yup.string(),
        ['assistaKilometerStatus']: Yup.string(),
        ['assistaVehicleInsurance']: Yup.string(),
        ['assistaComprehensiveInsurance']: Yup.string(),
        ['assistaComprehensiveLevel']: Yup.string()
            .when('assistaComprehensiveInsurance', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaDeductibleAmount']: Yup.string(),
        ['assistaReportedDamage']: Yup.string()
            .when('assistaComprehensiveInsurance', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaVehicleOwnerFirstname']: Yup.string(),
        ['assistaVehicleOwnerSurname']: Yup.string(),
        ['assistaVehicleOwnerCompany']: Yup.string(),
        ['assistaVehicleOwnerAddress']: Yup.string(),
        ['assistaVehicleOwnerAddressNPA']: Yup.string()
            .matches(/^[0-9]*$/, I18n.t('validateDefaultMessage')),
        ['assistaVehicleOwnerAddressCity']: Yup.string(),
        ['assistaOtherPartyMark']: Yup.string(),
        ['assistaOtherPartyModel']: Yup.string(),
        ['assistaIncidentCounterPartyName']: Yup.string(),
        ['assistaIncidentCounterPartyFirstname']: Yup.string(),
        ['assistaIncidentCounterPartyCompany']: Yup.string(),
        ['assistaIncidentCounterPartyAddress']: Yup.string(),
        ['assistaIncidentCounterPartyAddressNPA']: Yup.string(),
        ['assistaIncidentCounterPartyAddressCity']: Yup.string(),
        ['assistaIncidentCounterPartyAddressLand']: Yup.string(),
        ['assistaOtherPartyLiabilityInsurance']: Yup.string(),
        ['assistaVehicleOwnerDifferentName']: Yup.string(),
        ['assistaVehicleOwnerDifferentCompany']: Yup.string(),
        ['assistaVehicleOwnerDifferentFirstname']: Yup.string(),
        ['assistaVehicleOwnerDifferentAddress']: Yup.string(),
        ['assistaVehicleOwnerDifferentAddressNPA']: Yup.string(),
        ['assistaVehicleOwnerDifferentAddressCity']: Yup.string(),
        ['assistaVehicleOwnerDifferentAddressLand']: Yup.string(),
        ['assistaAnotherCounterParty']: Yup.string(),
        ['assistaAnotherCounterPartyName']: Yup.string(),
        ['assistaAnotherCounterPartyFirstname']: Yup.string(),
        ['assistaAnotherCounterPartyCompany']: Yup.string(),
        ['assistaAnotherCounterPartyAddress']: Yup.string(),
        ['assistaAnotherCounterPartyAddressNPA']: Yup.string(),
        ['assistaAnotherCounterPartyAddressCity']: Yup.string(),
        ['assistaAnotherCounterPartyAddressLand']: Yup.string(),
        ...validationSchemaBusinessLegalProtection3
    }),
    Yup.object().shape({
        ['assistaOtherProtection']: Yup.string().required(I18n.t('requiredDefaultMessage')),
        ['assistaProtectionName']: Yup.string()
            .when('assistaOtherProtection', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaCaseReported']: Yup.string()
            .when('assistaOtherProtection', {
                is: I18n.t('yesLabel'),
                then: Yup.string().required(I18n.t('requiredDefaultMessage'))
            }),
        ['assistaAuthorization']: Yup.boolean()
            .default(false)
            .oneOf([true], I18n.t('requiredDefaultMessage')),
        ...validationSchemaBusinessLegalProtection4
    })
];

export default schemaValidationArray;