import React from 'react';
import {ErrorMessage, useFormikContext} from 'formik';
import RadioComponent from "../../../common/Radio";
import InputComponent from "../../../common/Input";
import PersonItem from "../../../common/PersonItem";
import BusinessLegalProtection2 from "../subcomponents/businessLegalProtection/step/BusinessLegalProtection2";

export default function AssistaStep2({translation}) {
    const formikProps = useFormikContext();

    const updateOtherInsuredPerson = (event) => {
        formikProps.setFieldValue('assistaOtherInsuredPerson', event.currentTarget.value);

        if (event.currentTarget.value === 'no') {
            formikProps.setFieldValue('assistaOtherInsuredPersonGender', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonFirstname', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonSurname', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonBirthday', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonAddress', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonAddressNPA', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonAddressCity', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonPhone', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonEmail', '');
            formikProps.setFieldValue('assistaOtherInsuredPersonConnection', '');
        }
    }

    return (
        <>
            {formikProps.values['assistaFormChoice'] === translation('choice8') &&
                <BusinessLegalProtection2 translation={translation}/>
            }
            {translation('choice1') === formikProps.values['assistaFormChoice'] &&
                <>
                    <div className={'item'}>
                        <h4>
                            {translation("step2Label")}
                        </h4>
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPoliceNumber',
                                label: translation('policeNumberLabel') + '*',
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent data={{name: 'assistaInsurer', label: translation('insurerLabel') + '*'}}/>
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('genderLabel') + '*'}
                        </label>
                        <RadioComponent
                            data={{id: 'assistaGender1', value: 'male', name: 'assistaGender', label: translation('genderMaleLabel')}}/>
                        <RadioComponent
                            data={{id: 'assistaGender2', value: 'female', name: 'assistaGender', label: translation('genderFemaleLabel')}}/>
                        <RadioComponent
                            data={{id: 'assistaGender3', value: 'other', name: 'assistaGender', label: translation('genderOtherLabel')}}/>
                        <ErrorMessage className="text-danger" name='assistaGender' component="div" />
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaFirstname',
                                label: translation('firstnameLabel') + '*',
                                autoComplete: "given-name",
                                inputMode: "text"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaSurname',
                                label: translation('surnameLabel') + '*',
                                autoComplete: "family-name",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompanyName',
                                label: translation('companyNameLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaBirthday',
                                label: translation('birthdayLabel'),
                                autoComplete: "bday",
                                type: "date"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaAddress',
                                label: translation('addressLabel') + '*',
                                autoComplete: "street-address",
                                inputMode: "text",
                                component: "textarea",
                                rows: "3"
                            }}
                        />
                    </div>

                    <div className={'item-street'}>
                        <InputComponent
                            data={{
                                name: 'assistaAddressNPA',
                                label: translation('npaLabel') + '*',
                                autoComplete: "postal-code",
                                inputMode: "numeric"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaAddressCity',
                                label: translation('cityLabel') + '*',
                                autoComplete: "address-level2",
                                className: "city",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaLand',
                                label: translation('landLabel') + '*',
                                autoComplete: "country-name",
                                inputMode: "text"
                            }}
                        />
                    </div>


                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPhone',
                                label: translation('phoneNumberLabel') + '*',
                                autoComplete: "tel",
                                inputMode: "tel"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPhoneBis',
                                label: translation('phoneNumberBisLabel'),
                                inputMode: "tel"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaEmail',
                                label: translation('emailLabel') + '*',
                                autoComplete: "email",
                                inputMode: "email"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation('holderInformationLabelBis')}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('genderLabel')}
                        </label>
                        <RadioComponent
                            data={{id: 'assistaHolderGender1', value: 'male', name: 'assistaHolderGender', label: translation('genderMaleLabel')}}/>
                        <RadioComponent
                            data={{id: 'assistaHolderGender2', value: 'female', name: 'assistaHolderGender', label: translation('genderFemaleLabel')}}/>
                        <RadioComponent
                            data={{id: 'assistaHolderGender3', value: 'other', name: 'assistaHolderGender', label: translation('genderOtherLabel')}}/>
                        <ErrorMessage className="text-danger" name='assistaHolderGender' component="div" />
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderFirstname',
                                label: translation('firstnameLabel'),
                                inputMode: "text"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaHolderSurname',
                                label: translation('surnameLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderCompany',
                                label: translation('holderLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderBirthday',
                                label: translation('birthdayLabel'),
                                autoComplete: "bday",
                                type: "date"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderAddress',
                                label: translation('addressLabel'),
                                autoComplete: "street-address",
                                inputMode: "text",
                                component: "textarea",
                                rows: "3"
                            }}
                        />
                    </div>

                    <div className={'item-street'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderAddressNPA',
                                label: translation('npaLabel'),
                                autoComplete: "postal-code",
                                inputMode: "numeric"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaHolderAddressCity',
                                label: translation('cityLabel'),
                                autoComplete: "address-level2",
                                className: "city",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderPhone',
                                label: translation('phoneNumberSimpleLabel'),
                                autoComplete: "tel",
                                inputMode: "tel"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderEmail',
                                label: translation('emailLabel'),
                                autoComplete: "email",
                                inputMode: "email"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderConnection',
                                label: translation('holderConnectionLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('otherInsuredPersonLabel') + '*'}
                        </label>
                        <RadioComponent
                            data={{
                                id: 'assistaOtherInsuredPerson2',
                                value: translation('yesLabel'),
                                name: 'assistaOtherInsuredPerson',
                                label: translation('yesLabel')
                            }}
                            change={updateOtherInsuredPerson}
                        />
                        <RadioComponent
                            data={{
                                id: 'assistaOtherInsuredPerson1',
                                value: translation('noLabel'),
                                name: 'assistaOtherInsuredPerson',
                                label: translation('noLabel')
                            }}
                            change={updateOtherInsuredPerson}
                        />
                        <ErrorMessage className="text-danger" name='assistaOtherInsuredPerson' component="div" />
                    </div>

                    {formikProps.values['assistaOtherInsuredPerson'] === translation('yesLabel') &&
                        <>
                            <PersonItem translation={translation} prefix={'assistaOtherInsuredPerson'}/>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaOtherInsuredPersonConnection',
                                        label: translation('holderConnectionLabel') + '*',
                                        inputMode: "text"
                                    }}
                                />
                            </div>
                        </>
                    }

                    <div className={'item'}>
                        <h5>
                            {translation('paymentLabel')}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('bankAccountPaymentLabel')}
                        </label>
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaBankAccountPaymentInstitution',
                                label: translation('accountInstitutionLabel') + '*',
                                inputMode: "text"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaBankAccountPaymentIban',
                                label: translation('accountIbanLabel') + '*',
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaBankAccountPaymentHolder',
                                label: translation('accountHolderLabel') + '*',
                                inputMode: "text"
                            }}
                        />
                    </div>
                </>
            }
            {(translation('choice1') !== formikProps.values['assistaFormChoice']
                && translation('choice8') !== formikProps.values['assistaFormChoice'])  &&
                <>
                    <div className={'item'}>
                        <h4>
                            {translation("step2Label")}
                        </h4>
                    </div>

                    <div className={'item'}>
                        <InputComponent data={{name: 'assistaInsurer', label: translation('insurerLabel') + '*'}}/>
                    </div>

                    <div className={'item'}>
                        <InputComponent data={{name: 'assistaPoliceNumber', label: translation('policeNumberLabel') + '*'}}/>
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('genderLabel') + '*'}
                        </label>
                        <RadioComponent
                            data={{id: 'assistaGender1', value: 'male', name: 'assistaGender', label: translation('genderMaleLabel')}}/>
                        <RadioComponent
                            data={{id: 'assistaGender2', value: 'female', name: 'assistaGender', label: translation('genderFemaleLabel')}}/>
                        <RadioComponent
                            data={{id: 'assistaGender3', value: 'other', name: 'assistaGender', label: translation('genderOtherLabel')}}/>
                        <ErrorMessage className="text-danger" name='assistaGender' component="div" />
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaFirstname',
                                label: translation('firstnameLabel') + '*',
                                autoComplete: "given-name",
                                inputMode: "text"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaSurname',
                                label: translation('surnameLabel') + '*',
                                autoComplete: "family-name",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaCompanyName',
                                label: translation('companyNameLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaBirthday',
                                label: translation('birthdayLabel'),
                                autoComplete: "bday",
                                type: "date"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaAddress',
                                label: translation('addressLabel') + '*',
                                autoComplete: "street-address",
                                inputMode: "text",
                                component: "textarea",
                                rows: "3"
                            }}
                        />
                    </div>

                    <div className={'item-street'}>
                        <InputComponent
                            data={{
                                name: 'assistaAddressNPA',
                                label: translation('npaLabel') + '*',
                                autoComplete: "postal-code",
                                inputMode: "numeric"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaAddressCity',
                                label: translation('cityLabel') + '*',
                                autoComplete: "address-level2",
                                className: "city",
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaLand',
                                label: translation('landLabel') + '*',
                                autoComplete: "country-name",
                                inputMode: "text"
                            }}
                        />
                    </div>


                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPhone',
                                label: translation('phoneNumberLabel') + '*',
                                autoComplete: "tel",
                                inputMode: "tel"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaPhoneBis',
                                label: translation('phoneNumberBisLabel'),
                                inputMode: "tel"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaEmail',
                                label: translation('emailLabel') + '*',
                                autoComplete: "email",
                                inputMode: "email"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <h5>
                            {translation('holderInformationLabel')}
                        </h5>
                    </div>

                    <div className={'item'}>
                        <label className={'form-label'}>
                            {translation('genderLabel')}
                        </label>
                        <RadioComponent
                            data={{id: 'assistaHolderGender1', value: 'male', name: 'assistaHolderGender', label: translation('genderMaleLabel')}}/>
                        <RadioComponent
                            data={{id: 'assistaHolderGender2', value: 'female', name: 'assistaHolderGender', label: translation('genderFemaleLabel')}}/>
                        <RadioComponent
                            data={{id: 'assistaHolderGender3', value: 'other', name: 'assistaHolderGender', label: translation('genderOtherLabel')}}/>
                        <ErrorMessage className="text-danger" name='assistaHolderGender' component="div" />
                    </div>

                    <div className={'item-names'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderFirstname',
                                label: translation('firstnameLabel'),
                                inputMode: "text"
                            }}
                        />
                        <InputComponent
                            data={{
                                name: 'assistaHolderSurname',
                                label: translation('surnameLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>

                    <div className={'item'}>
                        <InputComponent
                            data={{
                                name: 'assistaHolderCompany',
                                label: translation('holderLabel'),
                                inputMode: "text"
                            }}
                        />
                    </div>
                </>
            }
        </>
    )
}