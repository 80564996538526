import React from 'react';
import {useFormikContext} from 'formik';
import AssistaSubStep1 from "../subcomponents/AssistaSubStep1";
import AssistaSubStep2 from "../subcomponents/AssistaSubStep2";
import AssistaSubStep3 from "../subcomponents/AssistaSubStep3";
import AssistaSubStep4 from "../subcomponents/AssistaSubStep4";
import InputComponent from "../../../common/Input";
import BusinessLegalProtection3 from "../subcomponents/businessLegalProtection/step/BusinessLegalProtection3";

export default function AssistaStep3({translation}) {
    const formikProps = useFormikContext();

    const renderSubComponent = () => {
        switch (formikProps.values['assistaFormChoice']) {
            case translation('choice3'):
                return <AssistaSubStep1 translation={translation} />;
            case translation('choice4'):
            case translation('choice5'):
            case translation('choice6'):
                return <AssistaSubStep2 translation={translation} />;
            case translation('choice7'):
                return <AssistaSubStep3 translation={translation} />;
            case translation('choice1'):
                return <AssistaSubStep4 translation={translation} />;
            default:
                return <div>Not Found</div>;
        }
    }

    const choices = [
        translation('choice1')
    ];

    function validateRequired(value) {
        let error;
        if (!value) {
            if (formikProps.values['assistaFormChoice'] !== ''
                && !choices.includes(formikProps.values['assistaFormChoice'])) {
                error = translation('requiredDefaultMessage');
            }
        }
        return error;
    }

    return (
        <>
            {formikProps.values['assistaFormChoice'] === translation('choice8') ?
                <BusinessLegalProtection3 translation={translation}/> :
                <>
                    <div className={'item'}>
                        <h4>
                            {translation("ASSISTA_Generic_Form_Label_Step3")}
                        </h4>
                    </div>

                    {renderSubComponent()}

                    {translation('choice1') !== formikProps.values['assistaFormChoice'] &&
                        <>
                            <div className={'item'}>
                                <h5>
                                    {translation("ASSISTA_Generic_Form_Step3_H3_Counter_Party")}
                                </h5>
                            </div>

                            <div className={'item-names'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCounterpartyFirstname',
                                        label: translation('firstnameLabel'),
                                        inputMode: "text"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCounterpartyName',
                                        label: translation('surnameLabel'),
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCounterpartyCompany',
                                        label: translation('holderLabel'),
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item-street'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCounterpartyAddress',
                                        label: translation('addressLabel'),
                                        inputMode: "text",
                                        component: "textarea",
                                        rows: "3"
                                    }}
                                />
                            </div>

                            <div className={'item-street'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCounterpartyAddressNPA',
                                        label: translation('npaLabel'),
                                        inputMode: "numeric"
                                    }}
                                />
                                <InputComponent
                                    data={{
                                        name: 'assistaCounterpartyAddressCity',
                                        label: translation('cityLabel'),
                                        className: "city",
                                        inputMode: "text"
                                    }}
                                />
                            </div>

                            <div className={'item'}>
                                <InputComponent
                                    data={{
                                        name: 'assistaCounterpartyAddressLand',
                                        label: translation('landLabel'),
                                        inputMode: "text"
                                    }}
                                />
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}