import React, {useState} from 'react';
import {Field, ErrorMessage, useFormikContext} from 'formik';
import RadioComponent from "../../../common/Radio";
import BusinessLegalProtection1 from "../subcomponents/businessLegalProtection/step/BusinessLegalProtection1";

export default function AssistaStep1({translation}) {
    const [formStart, setFormStart] = useState(false);

    const formikProps = useFormikContext();
    const resetForm = event => {
        if (!formStart) {
            setFormStart(true);
            window.parent.postMessage({
                event: "form_start"
            }, "*");
        }
        const { target: {value, name } } = event;
        formikProps.resetForm({values: ''});
        formikProps.setFieldValue(name, value);
    };

    const clearForm = event => {
        const assistaFormChoice = formikProps.values['assistaFormChoice'];
        const { target: {value, name } } = event;
        formikProps.resetForm({values: ''});
        formikProps.setFieldValue(name, value);
        formikProps.setFieldValue('assistaFormChoice', assistaFormChoice);
    };

    return (
        <>
            <div className={'item'}>
                <h3>
                    {translation('mainTitle')}
                </h3>
            </div>

            <div className={'item'}>
                <p>
                    {translation('ASSISTA_Generic_Form_Step1_Text_Welcome')}
                </p>
            </div>

            <div className={'item'}>
                <h4>
                    {translation("ASSISTA_Generic_Form_Label_Step1")}
                </h4>
            </div>

            <div className={'item'}>
                <label  className={'form-label'}>
                    {translation("ASSISTA_Generic_Form_Step1_Label_Insurance_Affected") + '*'}
                    <Field
                        name="assistaFormChoice"
                        as='select'
                        className={'form-select'}
                        onChange={resetForm}>
                        <option value=''/>
                        <option value={translation('choice1')}>
                            {translation('choice1')}
                        </option>
                        <option value={translation('choice4')}>
                            {translation('choice3')}
                        </option>
                        <option value={translation('choice6')}>
                            {translation('choice4')}
                        </option>
                        <option value={translation('choice5')}>
                            {translation('choice5')}
                        </option>
                        <option value={translation('choice3')}>
                            {translation('choice6')}
                        </option>
                        <option value={translation('choice7')}>
                            {translation('choice7')}
                        </option>
                        <option value={translation('choice8')}>
                            {translation('choice8')}
                        </option>
                    </Field>
                </label>
                <ErrorMessage className={'text-danger'} name="assistaFormChoice" component='div' />
            </div>

            {formikProps.values['assistaFormChoice'] === translation('choice8') ?
                <BusinessLegalProtection1 translation={translation}/> :
                <>
                    <div className={'item'}>
                        {formikProps.values['assistaFormChoice'] &&
                            <label className={'form-label'}>
                                {translation('damageReasonLabel') + '*'}
                            </label>
                        }

                        {translation('choice1') === formikProps.values['assistaFormChoice'] &&
                            <>
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage1',
                                        value: translation('incidentLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('incidentLabel') }}
                                    change={clearForm}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage4',
                                        value: translation('otherDisputeLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('otherDisputeLabel') }}
                                    change={clearForm}
                                />
                            </>
                        }

                        {translation('choice3') === formikProps.values['assistaFormChoice'] &&
                            <>
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage1',
                                        value: translation('trafficAccidentLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('trafficAccidentLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage2',
                                        value: translation('assaultWithHarmLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('assaultWithHarmLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage3',
                                        value: translation('theftLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('theftLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage4',
                                        value: translation('proceedingsRegardingLicenseLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('proceedingsRegardingLicenseLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage5',
                                        value: translation('rightTravelLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('rightTravelLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage6',
                                        value: translation('otherProblemLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('otherProblemLabel') }}
                                />
                            </>
                        }

                        {translation('choice4') === formikProps.values['assistaFormChoice'] &&
                            <>
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage1',
                                        value: translation('jobLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('jobLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage2',
                                        value: translation('insuranceLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('insuranceLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage3',
                                        value: translation('consumptionLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('consumptionLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage4',
                                        value: translation('hospitalLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('hospitalLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage5',
                                        value: translation('compensationLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('compensationLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage6',
                                        value: translation('procedureLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('procedureLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage7',
                                        value: translation('otherDisputeLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('otherDisputeLabel') }}
                                />
                            </>
                        }

                        {translation('choice5') === formikProps.values['assistaFormChoice'] &&
                            <>
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage1',
                                        value: translation('creditCardLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('creditCardLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage2',
                                        value: translation('ebankingLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('ebankingLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage3',
                                        value: translation('onlinePaymentLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('onlinePaymentLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage4',
                                        value: translation('cybermobbingLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('cybermobbingLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage5',
                                        value: translation('threatLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('threatLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage6',
                                        value: translation('buyingInternetLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('buyingInternetLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage7',
                                        value: translation('otherDisputeLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('otherDisputeLabel') }}
                                />
                            </>
                        }

                        {translation('choice6') === formikProps.values['assistaFormChoice'] &&
                            <>
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage1',
                                        value: translation('rentalContract'),
                                        name: 'assistaReasonDamage',
                                        label: translation('rentalContract') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage2',
                                        value: translation('insuranceLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('insuranceLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage3',
                                        value: translation('handymanLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('handymanLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage4',
                                        value: translation('otherDisputeLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('otherDisputeLabel') }}
                                />
                            </>
                        }

                        {translation('choice7') === formikProps.values['assistaFormChoice'] &&
                            <>
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage1',
                                        value: translation('criminalProceedingsLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('criminalProceedingsLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage2',
                                        value: translation('compensationLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('compensationLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage3',
                                        value: translation('insuranceLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('insuranceLabel')
                                    }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage4',
                                        value: translation('neighborRightLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('neighborRightLabel') }}
                                />
                                <RadioComponent
                                    data={{
                                        id: 'assistaReasonDamage4',
                                        value: translation('otherDisputeLabel'),
                                        name: 'assistaReasonDamage',
                                        label: translation('otherDisputeLabel') }}
                                />
                            </>
                        }


                        {formikProps.values['assistaFormChoice'] &&
                            <ErrorMessage className={'text-danger'} name='assistaReasonDamage' component='div' />
                        }
                    </div>

                    <div className={'item'}>
                        {formikProps.values['assistaFormChoice'] &&
                            <label className={'form-label'}>
                                {translation('documentsAndInfosLabel')}
                            </label>
                        }

                        {translation('choice1') === formikProps.values['assistaFormChoice'] &&
                            <ul>
                                <li>{translation('accidentReportLabel')}</li>
                                <li>{translation('penaltyLabel')}</li>
                                <li>{translation('penaltyCorrespondence')}</li>
                                <li>{translation('etCeteraLabel')}</li>
                            </ul>
                        }

                        {translation('choice3') === formikProps.values['assistaFormChoice'] &&
                            <ul>
                                <li>{translation('accidentReportLabel')}</li>
                                <li>{translation('penaltyLabel')}</li>
                                <li>{translation('etCeteraLabel')}</li>
                            </ul>
                        }

                        {(translation('choice4') === formikProps.values['assistaFormChoice']
                                || translation('choice5') === formikProps.values['assistaFormChoice']
                                || translation('choice6') === formikProps.values['assistaFormChoice']
                                || translation('choice7') === formikProps.values['assistaFormChoice']) &&
                            <ul>
                                <li>{translation('contractLabel')}</li>
                                <li>{translation('correspondenceLabel')}</li>
                                <li>{translation('etCeteraLabel')}</li>
                            </ul>
                        }
                    </div>
                </>
            }
        </>
    )
}