import React from "react";
import {useFormikContext} from "formik";

export default function BusinessLegalProtection1({translation}) {
    const formikProps = useFormikContext();

    return (
        <>
            <div className={'item'}>
                {formikProps.values['assistaFormChoice'] &&
                    <>
                        <label className={'form-label'}>
                            {translation("ASSISTA_Generic_Form_Step1_Text_Prepare_All_Documents")}
                        </label>

                        <ul>
                            <li>{translation("ASSISTA_Generic_Form_Step1_Text_Insurance_Policy")}</li>
                            <li>{translation("ASSISTA_Generic_Form_Step1_Text_Correspondence")}</li>
                            <li>{translation("ASSISTA_Generic_Form_Step1_Text_Accident_Report")}</li>
                            <li>{translation("ASSISTA_Generic_Form_Step1_Text_Penalty")}</li>
                            <li>{translation("ASSISTA_Generic_Form_Step1_Text_Data_From_Witnesses")}</li>
                            <li>{translation("etCeteraLabel")}</li>
                        </ul>
                    </>
                }
            </div>
        </>
    )
}