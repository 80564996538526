import React, {useEffect} from "react";

export default function ConfirmationComponent({status, translation, i18n}) {
    const confirmation = (status === 200 || status === 202);

    const videosId = {
        fr: "qDkr_eic-UY",
        de: "VRlEJpHvp3M",
        it: "9t4_BidEfBM"
    }

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'instant'});
            if ('parentIFrame' in window) {
                window.parentIFrame.scrollTo(0, 0);
            }
        }, 0);
    });

    return (
        <>
            {(() => {
                if (confirmation) {
                    return (
                        <>
                            <div className={'item-message'}>
                                <h6 style={{fontWeight: "bold"}}>
                                    {translation('successMessage')}
                                </h6>
                            </div>

                            <div className={'item-message'}>
                                <p>{translation('successConfirmationEmail')}</p>
                            </div>

                            <div className={'item-message'}>
                                <p>{translation('ASSISTA_Generic_Form_Confirmation_Text_Disclaimer')}</p>
                            </div>

                            <div className={'item-message'}>
                                <p>{translation('videoText')}</p>
                            </div>

                            <div className={'item-message'}>
                                <iframe
                                    class="video"
                                    src={"https://www.youtube.com/embed/" + videosId[i18n.language] + "?hl=" + i18n.language}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen>
                                </iframe>
                            </div>

                            <div className={'item-message'}>
                                <a href={"https://assista.ch/" + i18n.language ?? "de"}
                                   target="_parent">{translation('closeWindowsLabel')}</a>
                            </div>

                            <hr/>

                            <div className={'item-message'}>
                                <p>{translation('confirmationAssistaSa')}</p>
                                <p>{translation('confirmationEmail')}</p>
                                <p>{translation('confirmationTel')}</p>
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className={'item-message'}>
                                <h6>
                                    {translation('errorMessage')}
                                </h6>
                            </div>

                            <div className={'item-message'}>
                                <a href={"https://assista.ch/" + i18n.language ?? "de" + "/kontakt"} target="_parent">{translation('contactSupportLabel')}</a>
                            </div>

                            <hr/>

                            <div className={'item-message'}>
                                <p>{translation('confirmationAssistaSa')}</p>
                                <p>{translation('confirmationEmail')}</p>
                                <p>{translation('confirmationTel')}</p>
                            </div>
                        </>
                    )
                }
            })()}
        </>
    )
}