import React from "react";
import {useFormikContext} from "formik";
import LineComponent from "../../../../../common/ui/Line";

export default function BusinessLegalProtection2({translation, printRef}) {
    const formikProps = useFormikContext();

    const getLabelGender = (gender) => {
        switch (gender) {
            case 'male':
                return translation('genderMaleLabel');
            case 'female':
                return translation('genderFemaleLabel');
            case 'other':
            default:
                return translation('genderOtherLabel');
        }
    }

    return (
        <div ref={printRef}>
            <div className={'item'}>
                <h5>{translation("ASSISTA_Generic_Form_Label_Step2")}</h5>
            </div>

            <div className={'item'}>
                <h6>
                    {translation("ASSISTA_Company_Form_Step2_H2_Company_Information")}
                </h6>
            </div>

            <div className={'item'}>
                <p>
                    {translation("ASSISTA_Company_Form_Step2_Text_Company_Information")}
                </p>
            </div>

            <div className={'item'}>
                <h6>{translation('policeNumberLabel')}</h6>
                <p>{formikProps.values['assistaPoliceNumber']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation('ASSISTA_Company_Form_Step2_Field_Which_Partner')}</h6>
                <p>{formikProps.values['assistaInsurer']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Company_Form_Step2_Field_Company_Name")}</h6>
                <p>{formikProps.values['assistaCompanyName']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                <p>{formikProps.values['assistaCompanyAddress']}</p>
            </div>

            <div className={'item-street'}>
                <div>
                    <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                    <p>{formikProps.values['assistaCompanyAddressNPA']}</p>
                </div>
                <div>
                    <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                    <p>{formikProps.values['assistaCompanyAddressCity']}</p>
                </div>
            </div>

            <div className={'item'}>
                <h6>
                    {translation("ASSISTA_Company_Form_Step2_H2_Information_Contact")}
                </h6>
            </div>

            <div className={'item'}>
                <div>
                    <p>{getLabelGender(formikProps.values['assistaGender'])}</p>
                </div>
            </div>

            <div className={'item-names'}>
                <div>
                    <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                    <p>{formikProps.values['assistaFirstname']}</p>
                </div>
                <div>
                    <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                    <p>{formikProps.values['assistaSurname']}</p>
                </div>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Company_Form_Field_Job")}</h6>
                <p>{formikProps.values['assistaCompanyJob']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Field_Phone_1")}</h6>
                <p>{formikProps.values['assistaPhone']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Field_Phone_2")}</h6>
                <p>{formikProps.values['assistaPhoneBis']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Field_Email")}</h6>
                <p>{formikProps.values['assistaEmail']}</p>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Company_Form_Step2_Label_Who_Affected")}</h6>
                <p>{formikProps.values['assistaAffectedByDisaster']}</p>
            </div>

            {formikProps.values['assistaAffectedByDisaster'] === translation("ASSISTA_Company_Form_Step2_Field_Who_Affected_Private") &&
                <>
                    <div className={'item'}>
                        <h6>
                            {translation("ASSISTA_Company_Form_Step2_H3_Information_Person_Needs_Protection")}
                        </h6>
                    </div>

                    <div className={'item'}>
                        <div>
                            <p>{getLabelGender(formikProps.values['assistaPersonAffectedGender'])}</p>
                        </div>
                    </div>

                    <div className={'item-names'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Firstname")}</h6>
                            <p>{formikProps.values['assistaPersonAffectedFirstname']}</p>
                        </div>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Surename")}</h6>
                            <p>{formikProps.values['assistaPersonAffectedSurname']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Street_Number")}</h6>
                            <p>{formikProps.values['assistaPersonAffectedAddress']}</p>
                        </div>
                    </div>

                    <div className={'item-street'}>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_NPA")}</h6>
                            <p>{formikProps.values['assistaPersonAffectedAddressNPA']}</p>
                        </div>
                        <div>
                            <h6>{translation("ASSISTA_Generic_Form_Field_Location")}</h6>
                            <p>{formikProps.values['assistaPersonAffectedAddressCity']}</p>
                        </div>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Country")}</h6>
                        <p>{formikProps.values['assistaPersonAffectedLand']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Phone")}</h6>
                        <p>{formikProps.values['assistaPersonAffectedPhone']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Generic_Form_Field_Email")}</h6>
                        <p>{formikProps.values['assistaPersonAffectedEmail']}</p>
                    </div>

                    <div className={'item'}>
                        <h6>{translation("ASSISTA_Company_Form_Step2_Field_Connection_With_Company")}</h6>
                        <p>{formikProps.values['assistaPersonAffectedLink']}</p>
                    </div>
                </>
            }

            <div className={'item'}>
                <h6>
                    {translation("ASSISTA_Generic_Form_H3_Bank_Details")}
                </h6>
            </div>

            <div className={'item'}>
                <div>
                    <h6>{translation("ASSISTA_Generic_Form_Field_Financial_Institution")}</h6>
                    <p>{formikProps.values['assistaBankAccountPaymentInstitution']}</p>
                </div>
            </div>

            <div className={'item'}>
                <div>
                    <h6>{translation("ASSISTA_Generic_Form_Field_Iban")}</h6>
                    <p>{formikProps.values['assistaBankAccountPaymentIban']}</p>
                </div>
            </div>

            <div className={'item'}>
                <h6>{translation("ASSISTA_Generic_Form_Field_Account_Holder")}</h6>
                <p>{formikProps.values['assistaBankAccountPaymentHolder']}</p>
            </div>

            <LineComponent/>
        </div>
    )
}