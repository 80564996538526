import {useEffect} from "react";
import {useFormikContext} from "formik";

export default function ErrorFocusComponent() {
    const formikProps = useFormikContext();

    useEffect(() => {
        if (formikProps.isSubmitting && !formikProps.isValidating) {
            let keys = Object.keys(formikProps.errors);
            if (keys.length > 0) {
                let selector = `[name="${keys[0]}"]`;
                let errorElement = document.querySelector(selector);

                if (errorElement !== null) {
                    let rect = errorElement.getBoundingClientRect();
                    if ('parentIFrame' in window) {
                        window.parentIFrame.scrollTo(0, rect.top);
                    }
                }
            }
        }
    }, [formikProps]);

    return null
}