import * as Yup from "yup";
import I18n from "../../../../i18n";

export const validationSchemaBusinessLegalProtection4 = {
    ['assistaCompanyCaseReportedNumber']: Yup.string()
        .when(['assistaFormChoice', 'assistaCaseReported'], (assistaFormChoice, assistaCaseReported, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCaseReported === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyRefused']: Yup.string()
        .when(['assistaFormChoice'], (assistaFormChoice, schema) => {
            if (I18n.t('choice8') === assistaFormChoice) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        }),
    ['assistaCompanyRefusedName']: Yup.string()
        .when(['assistaFormChoice', 'assistaCompanyRefused'], (assistaFormChoice, assistaCompanyRefused, schema) => {
            if (I18n.t('choice8') === assistaFormChoice &&
                assistaCompanyRefused === I18n.t('yesLabel')
            ) {
                return Yup.string().required(I18n.t('requiredDefaultMessage'));
            }
        })
}