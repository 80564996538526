export const initialValuesBusinessLegalProtection = {
    ['assistaCompanyJob']: '',
    ['assistaCompanyAddress']: '',
    ['assistaCompanyAddressNPA']: '',
    ['assistaCompanyAddressCity']: '',
    ['assistaAffectedByDisaster']: '',
    ['assistaPersonAffectedGender']: '',
    ['assistaPersonAffectedFirstname']: '',
    ['assistaPersonAffectedSurname']: '',
    ['assistaPersonAffectedAddress']: '',
    ['assistaPersonAffectedAddressNPA']: '',
    ['assistaPersonAffectedAddressCity']: '',
    ['assistaPersonAffectedLand']: '',
    ['assistaPersonAffectedPhone']: '',
    ['assistaPersonAffectedEmail']: '',
    ['assistaPersonAffectedLink']: '',
    ['assistaCompanyLegalCase']: '',
    ['assistaCompanyTypeDispute']: '',
    ['assistaCompanyDateDamage']: '',
    ['assistaCompanyExactPlace']: '',
    ['assistaCompanyLand']: '',
    ['assistaCompanyDescriptionCaseDamage']: '',
    ['assistaCompanyCompensation']: '',
    ['assistaCompanyCounterPartyHolderName']: '',
    ['assistaCompanyCounterPartyGender']: '',
    ['assistaCompanyCounterPartyFirstname']: '',
    ['assistaCompanyCounterPartySurname']: '',
    ['assistaCompanyCounterPartyAddress']: '',
    ['assistaCompanyCounterPartyAddressNPA']: '',
    ['assistaCompanyCounterPartyAddressCity']: '',
    ['assistaCompanyCounterPartyAddressLand']: '',
    ['assistaCompanyAccidentReportMade']: '',
    ['assistaCompanySignedAcknowledgment']: '',
    ['assistaCompanySignedAcknowledgmentOpposingParty']: '',
    ['assistaCompanyPoliceHere']: '',
    ['assistaCompanyPoliceStation']: '',
    ['assistaCompanyAccusedOf']: '',
    ['assistaCompanyLicenseTakenAway']: '',
    ['assistaCompanyBloodTest']: '',
    ['assistaCompanyCriminalMeasure']: '',
    ['assistaCompanyPoliceReportMade']: '',
    ['assistaCompanyPeopleInjured']: '',
    ['assistaCompanyPeopleInjuredInformations']: '',
    ['assistaCompanyDeclarationAccidentInsurance']: '',
    ['assistaCompanyOtherPeopleInjured']: '',
    ['assistaCompanyOtherPeopleInjuredInformations']: '',
    ['assistaCompanyOtherDeclarationAccidentInsurance']: '',
    ['assistaCompanyMark']: '',
    ['assistaCompanyModel']: '',
    ['assistaCompanyFirstDatePlacingMarket']: '',
    ['assistaCompanyKilometerStatus']: '',
    ['assistaCompanyItemsDamaged']: '',
    ['assistaCompanyListItemsDamaged']: '',
    ['assistaCompanyOwnerItemsDamagedCompany']: '',
    ['assistaCompanyOwnerItemsDamagedGender']: '',
    ['assistaCompanyOwnerItemsDamagedFirstname']: '',
    ['assistaCompanyOwnerItemsDamagedSurname']: '',
    ['assistaCompanyOwnerItemsDamagedAddress']: '',
    ['assistaCompanyOwnerItemsDamagedAddressNPA']: '',
    ['assistaCompanyOwnerItemsDamagedAddressCity']: '',
    ['assistaCompanyOwnerItemsDamagedAddressLand']: '',
    ['assistaCompanyVehicleAssessed']: '',
    ['assistaCompanyVehicleExpertMandated']: '',
    ['assistaCompanyVehicleRepaired']: '',
    ['assistaCompanyVehicleRepairedBy']: '',
    ['assistaCompanyNameInsuranceVehicle']: '',
    ['assistaCompanyPoliceNumberVehicle']: '',
    ['assistaCompanyVehicleDeclaredIncident']: '',
    ['assistaCompanyVehicleCasco']: '',
    ['assistaCompanyCascoPoliceNumber']: '',
    ['assistaCompanyCascoFullOrPartial']: '',
    ['assistaCompanyCascoFranchise']: '',
    ['assistaCompanyVehicleCascoDeclared']: '',
    ['assistaCompanyVehicleCompanyOwner']: '',
    ['assistaCompanyVehicleOwnerAddress']: '',
    ['assistaCompanyVehicleOwnerAddressNPA']: '',
    ['assistaCompanyVehicleOwnerAddressCity']: '',
    ['assistaCompanyVehicleOwnerAddressLand']: '',
    ['assistaCompanyVehicleDriverGender']: '',
    ['assistaCompanyVehicleDriverFirstname']: '',
    ['assistaCompanyVehicleDriverSurname']: '',
    ['assistaCompanyVehicleDriverAddress']: '',
    ['assistaCompanyVehicleDriverAddressNPA']: '',
    ['assistaCompanyVehicleDriverAddressCity']: '',
    ['assistaCompanyVehicleDriverAddressLand']: '',
    ['assistaCompanyVehicleDriverLicenseCategory']: '',
    ['assistaCompanyVehicleDriverLicenseDate']: '',
    ['assistaCompanyCounterPartyMark']: '',
    ['assistaCompanyCounterPartyModel']: '',
    ['assistaCompanyCounterPartyNameInsuranceVehicle']: '',
    ['assistaCompanyCounterPartyOwner']: '',
    ['assistaCompanyWitness']: '',
    ['assistaCompanyWitness1Gender']: '',
    ['assistaCompanyWitness1Firstname']: '',
    ['assistaCompanyWitness1Surname']: '',
    ['assistaCompanyOtherWitness']: '',
    ['assistaCompanyWitness2Gender']: '',
    ['assistaCompanyWitness2Firstname']: '',
    ['assistaCompanyWitness2Surname']: '',
    ['assistaCompanyCaseReportedNumber']: '',
    ['assistaCompanyRefused']: '',
    ['assistaCompanyRefusedName']: ''
}